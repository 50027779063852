import { Component, Input } from '@angular/core';

@Component({
  selector: 'ff-message-divider',
  templateUrl: './ff-message-divider.component.html',
})
export class FFMessageDividerComponent {
  @Input() alertType: AlertType = AlertType.Success;
  @Input() message?: string;
  @Input() title?: string;
  @Input() subTitle?: string;
  @Input() icon?: string;

  alertTypeEnum: typeof AlertType = AlertType;
}

export enum AlertType {
  Information = 'alert-primary alert-divider-information',
  Alert = 'alert-danger alert-divider-danger',
  Success = 'alert-success alert-divider-success',
  StepSubmittedHistory = 'alert-info alert-divider-history',
  Warning = 'alert-warning alert-divider-warning',
}
