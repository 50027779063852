import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, take } from 'rxjs';

import {
  FlowService,
  FormReopenRequest,
  PatchFlowEventResponse,
  PatchFlowRequest,
  PatchFlowTableEventResponse,
  RuleTriggerEvent,
  StepAttachmentUploadRequest,
  StepFeedbackRequest,
  StepNoteRequest,
  StepReopenRequest,
  SubmitQuestionRequest,
  SubmitStepRequest,
  TableOperationType,
} from '../../../common/services/flows/flow.service';

import { IStep } from '../../../models/IStep';
import { IFlow } from '../../../models/IFlow';
import { Section } from '../../../models/Section';
import { BaseQuestion, Question } from '../../../models/Question';
import {
  AddFeedbackEvent,
  FormCancelEvent,
  QuestionEvent,
  StepAttachmentEvent,
  StepDetailComponent,
  StepEvent,
} from '../../steps/step-detail/step-detail.component';
import { QuestionType } from '../../../models/QuestionType';
import { FFModalService } from '../../../visual-components/services/ff-modal.service';
import { TableAnswer, TableQuestion } from '../../../models/TableQuestion';
import { StepSubmittedAction } from '../../steps/step-submitted/step-submitted.component';
import { URIService } from '../../../common/services/uri.service';
import { Feedback } from '../../../models/Feedback';
import { FeedbackPanel } from '../feedback-panel/feedback-panel.model';
import { FlowEvent } from '../../../models/FlowEvent';
import {
  CreateCommentThreadDto,
  ArchivedComments,
} from '../../questions/question-comment/question-comment.model';
import { Note } from '../../../models/Note';
import { FormReopeningData } from '../form-reopening-confirmation-dialog/form-reopening-confirmation-dialog.component';
import { StepAttachmentAction } from '../../../models/Attachment';
import {
  ButtonSize,
  ButtonType,
} from '../../../visual-components/components/ff-button/ff-button.component';
import { ScrollLockService } from '../../../common/services/scroll-lock.service';
import { RelativeTimePipe } from '../../../common/pipes/relative-time.pipe';
import { DatePipe } from '@angular/common';
import { FileUpload } from '../../../models/FileUpload';
import { FormHistory } from '../../../models/FormHistory';
import {
  AffectedQuestion,
  AffectedQuestionAction,
} from '../../../models/AffectedQuestion';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  @ViewChild(StepDetailComponent) stepDetailComponent?: StepDetailComponent;
  @Input() form!: FormGroup;

  flow$?: Observable<IFlow>;
  flowEvents: FlowEvent[] = [];
  allStepsTitles: Map<string, string> = new Map<string, string>();
  selectedStep?: IStep;
  formId?: number;
  flowId?: number;
  formGuid?: string;
  ruleTriggerEvent: typeof RuleTriggerEvent = RuleTriggerEvent;
  questionType: typeof QuestionType = QuestionType;
  showQAButtons: boolean = false;
  lastStep: boolean = false;
  stepIndex: number = 0;
  flow?: IFlow;
  feedbackPanelData?: FeedbackPanel;
  isSummarySelected: boolean = false;
  isWorkflowHistorySelected: boolean = false;
  reopenMode: boolean = false;
  isSideStepperExpanded: boolean = false;
  isMinWidthLg: boolean = false;
  currentShowComponentEnum: AvailableComponent = AvailableComponent.StepDetail;
  availableComponentEnum = AvailableComponent;
  buttonTypeEnum: typeof ButtonType = ButtonType;
  buttonSizeEnum: typeof ButtonSize = ButtonSize;
  archivedQuestionComments: (ArchivedComments | undefined)[] = [];
  isAffectedQuestionsWithErrors: boolean = false;
  currentTime: Date = new Date();
  currentTimeUpdateInterval?: NodeJS.Timeout;
  defaultLogoImage: string = 'assets/default_logo.png';
  poweredByLogo: string = 'assets/powered_by_logo.png';
  constructor(
    private _flowService: FlowService,
    private _route: ActivatedRoute,
    private _modalService: FFModalService,
    private _uriService: URIService,
    private _scrollLockService: ScrollLockService,
    private _datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.initializeQAButtons();
    this.subscribeToFlowUpdates();
  }

  initializeForm(): void {
    const flowIdParam = this._route.snapshot.queryParamMap.get('flowId');
    const formIdParam = this._route.snapshot.queryParamMap.get('formId');

    this.flowId = flowIdParam ? Number(flowIdParam) : undefined;
    this.formId = formIdParam ? Number(formIdParam) : undefined;

    if (this.flowId) {
      this.flow$ = this._flowService.get(this.flowId, this.formId);
    } else {
      this.flow$ = this._flowService.get(this.formId!);
    }
  }

  initializeQAButtons(): void {
    const localStorageQAValue = localStorage.getItem('showQAButtons');
    this.showQAButtons = localStorageQAValue
      ? JSON.parse(localStorageQAValue)
      : false;
  }

  subscribeToFlowUpdates(): void {
    this.flow$?.subscribe((flow: IFlow) => {
      this.updateFlow(flow);
    });
  }

  /**
   * @description Exists the form, calls save if form isn't complete
   * @returns { void }
   */
  public saveAndExit(): void {
    if (this.flow?.isFlowCompleted) {
      this._modalService.openConfirmationDialog(
        'Save and exit confirmation',
        'Are you sure you want to want to save and exit the form?',
        () => {
          this._uriService.redirectToFlowFormaFormsList();
        },
      );
    } else {
      this.saveAndExitNotCompletedFlow();
    }
  }

  saveAndExitNotCompletedFlow(): void {
    if (this.reopenMode) this.exitInReopenMode();
    else if (this.selectedStep?.isActive && !this.selectedStep?.isReadOnly)
      this.saveAndExitActiveStep();
    else this.exitCompletedStep();
  }

  saveAndExitActiveStep(): void {
    if (this.selectedStep?.dueDate) {
      if (new Date(this.selectedStep.dueDate) < this.currentTime) {
        this._modalService.openConfirmationDialog(
          'Close step?',
          'Closing now will save your progress. Remember, your step(s) were due ' +
            new RelativeTimePipe().transform(
              this.selectedStep.dueDate,
              this.currentTime,
              'ago',
            ) +
            ', <span class="fw-bold">' +
            this._datePipe.transform(this.selectedStep.dueDate, 'EEEE dd MMM') +
            '</span>.',
          () => {
            this.saveStepOnExit();
            this._uriService.redirectToFlowFormaFormsList();
          },
        );
      } else {
        this._modalService.openConfirmationDialog(
          'Close step?',
          'Closing now will save your progress. Remember, your step(s) are due by: <span class="fw-bold">' +
            this._datePipe.transform(this.selectedStep.dueDate, 'EEEE dd MMM') +
            '</span>.',
          () => {
            this.saveStepOnExit();
            this._uriService.redirectToFlowFormaFormsList();
          },
        );
      }
    } else {
      this._modalService.openConfirmationDialog(
        'Close step?',
        'Closing now will save your progress and allow you to come back to it at a later date.',
        () => {
          this.saveStepOnExit();
          this._uriService.redirectToFlowFormaFormsList();
        },
      );
    }
  }

  exitInReopenMode(): void {
    this._modalService.openConfirmationDialog(
      'Close step?',
      'Closing now will save your progress and allow you to come back to it at a later date (In reopen).',
      () => {
        this._uriService.redirectToFlowFormaFormsList();
      },
    );
  }

  exitCompletedStep(): void {
    this._modalService.openConfirmationDialog(
      'Close step?',
      'Closing now will save your progress and allow you to come back to it at a later date.',
      () => {
        this._uriService.redirectToFlowFormaFormsList();
      },
    );
  }

  saveStepOnExit(): void {
    this._flowService
      .submitStep(
        new SubmitStepRequest(
          this.formGuid!,
          this.selectedStep!.id!,
          RuleTriggerEvent.StepSaving,
        ),
      )
      .subscribe((response: PatchFlowEventResponse) => {});
  }

  private loadFormGroup(): void {
    const group: CustomFormControl = {};
    this.selectedStep?.questions?.forEach((question) => {
      switch (question.questionType) {
        case this.questionType.Section:
          this.handleSectionQuestion(group, question);
          break;
        case this.questionType.Table:
          this.handleTableQuestion(group, question);
          break;
        default:
          this.addQuestionFormControl(group, question);
          break;
      }
    });

    this.form = new FormGroup(group);
  }

  private handleSectionQuestion(
    group: CustomFormControl,
    question: BaseQuestion,
  ): void {
    const section = question as Section;
    section.questions?.forEach((sectionQuestion) => {
      this.addQuestionFormControl(group, sectionQuestion);
    });
  }

  private handleTableQuestion(
    group: CustomFormControl,
    question: BaseQuestion,
  ): void {
    const questionTable = question as TableQuestion;
    this.addQuestionFormControl(group, questionTable);

    for (const tableColumn of questionTable.tableColumns ?? []) {
      for (const tableCell of tableColumn.cells ?? []) {
        this.addTableRow(tableColumn.headerQuestion, tableCell, group);
      }
      for (const footerQuestion of tableColumn.footerQuestions ?? []) {
        this.addQuestionFormControl(group, footerQuestion);
      }
    }
  }

  private addTableRow(
    headerQuestion: Question<void>,
    tableCell: TableAnswer,
    group: CustomFormControl,
  ) {
    const mappedQuestion: Question<typeof tableCell.value> = {
      ...headerQuestion,
      id: tableCell.id,
      value: tableCell.value,
    };

    this.addQuestionFormControl(group, mappedQuestion);
  }

  /**
   * @description Updates the form group for the affected questions
   * @param { AffectedQuestion[] | undefined } affectedQuestions List of affected questions
   * @returns { void }
   */
  updateFormGroup(
    affectedQuestions: AffectedQuestion[] | undefined,
    flow: IFlow,
  ): void {
    affectedQuestions?.forEach((affectedQuestion) => {
      if (affectedQuestion.actions) {
        affectedQuestion.actions.forEach((action) => {
          switch (action) {
            case AffectedQuestionAction.Hide:
              this.handleQuestionActionHide(affectedQuestion);
              break;
            case AffectedQuestionAction.Show:
              this.handleQuestionActionShow(affectedQuestion, flow);
              break;
            case AffectedQuestionAction.Enable:
              this.handleQuestionActionState(affectedQuestion, true);
              break;
            case AffectedQuestionAction.Disable:
              this.handleQuestionActionState(affectedQuestion, false);
              break;
            case AffectedQuestionAction.DataValidationError:
              this.isAffectedQuestionsWithErrors = true;
              this.updateControlWithValidationMessage(
                affectedQuestion.questionId,
                affectedQuestion.errorMessage,
              );
              break;
            case AffectedQuestionAction.TableQuestionUpdate: {
              // Filter questions for only table questions
              const filteredItems = this.selectedStep?.questions.filter(
                (question) => question.questionType === QuestionType.Table,
              ) as TableQuestion[];
              // Find table column by Id
              const matchingColumn = filteredItems
                .flatMap((table) => table.tableColumns)
                .find(
                  (column) =>
                    column!.headerQuestion.id === affectedQuestion.questionId,
                );
              if (matchingColumn) {
                // Find cell by row number
                const matchingCell = matchingColumn.cells.find(
                  (cell) => cell.rowNumber === affectedQuestion.rowNumber,
                );
                // Update question value
                this.updateFormValue(
                  matchingCell?.id!,
                  affectedQuestion.newValue,
                );
              }
              break;
            }
            case AffectedQuestionAction.QuestionUpdate:
              this.updateFormValue(
                affectedQuestion.questionId,
                affectedQuestion.newValue,
              );
              break;
          }
        });
      }
    });
  }

  /**
   * @description Handles the hide action for a question,
   * removes the form control and question from the selected step.
   * @param { AffectedQuestion } affectedQuestion Affected question
   * @returns { void }
   */
  private handleQuestionActionHide(affectedQuestion: AffectedQuestion): void {
    const questionIndex = this.selectedStep?.questions.findIndex(
      (question) => question.id === affectedQuestion.questionId,
    );

    if (
      this.form.get(affectedQuestion.questionId) &&
      questionIndex !== undefined &&
      questionIndex > -1
    ) {
      this.form.removeControl(affectedQuestion.questionId);
      this.selectedStep!.questions.splice(questionIndex, 1);
    }
  }

  /**
   * @description Handles the show action for a question,
   * adds question to the selected step and the form control.
   * @param { AffectedQuestion } affectedQuestion Affected question
   * @param { IFlow } flow Flow of the form
   * @returns { void }
   */
  private handleQuestionActionShow(
    affectedQuestion: AffectedQuestion,
    flow: IFlow,
  ): void {
    const newSelectedStep = flow.steps.find(
      (step) => step.id === this.selectedStep?.id,
    );
    const question = newSelectedStep?.questions?.find(
      (question) => question.id === affectedQuestion.questionId,
    );

    if (!this.form.get(affectedQuestion.questionId) && question) {
      this.addQuestionToSelectedStepByQuestionIndex(question);

      this.addQuestionFormControl(
        this.form.controls as CustomFormControl,
        question,
      );
    }
  }

  /**
   * @description Handles the action state for a question,
   * enables or disables the question and the form control.
   * @param { AffectedQuestion } affectedQuestion Affected question
   * @param { boolean } state State of the question, enabled or disabled
   * @returns { void }
   */
  private handleQuestionActionState(
    affectedQuestion: AffectedQuestion,
    state: boolean,
  ): void {
    const questionToState = this.selectedStep?.questions.find(
      (question) => question.id === affectedQuestion.questionId,
    );
    const controlToState = this.form.get(affectedQuestion.questionId);

    if (controlToState && questionToState) {
      questionToState.enabled = state;
      state ? controlToState.enable() : controlToState.disable();
    }
  }

  /**
   * @description Adds the question to the selected step by question index
   * @param { BaseQuestion } newQuestion Question to be added to selected step
   * @returns { void }
   */
  addQuestionToSelectedStepByQuestionIndex(newQuestion: BaseQuestion): void {
    const insertIndex = this.selectedStep?.questions.findIndex(
      (q) => q.index > newQuestion.index,
    );

    if (insertIndex == undefined) {
      return;
    }
    if (insertIndex === -1) {
      this.selectedStep!.questions.push(newQuestion);
    } else {
      this.selectedStep!.questions.splice(insertIndex, 0, newQuestion);
    }
  }

  /**
   * @description Updates the form control with error message
   * @param { string } questionId The Id of question to update
   * @param { string } errorMessage Validation error message
   * @returns { void }
   */
  updateControlWithValidationMessage(
    questionId: string,
    errorMessage?: string,
  ): void {
    const formControlToPatch = this.form.get(questionId);
    if (
      !formControlToPatch?.errors?.[
        StepDetailComponent.dataValidationErrorKey
      ] &&
      errorMessage
    ) {
      formControlToPatch?.addValidators(
        this.customErrorValidator(errorMessage),
      );
      formControlToPatch?.markAsDirty();
      formControlToPatch!.updateValueAndValidity();
    }
  }

  /**
   * @description Validator for custom error message
   * @param { string } errorMessage Validation error message
   * @returns { ValidatorFn } Validator
   */
  customErrorValidator(errorMessage?: string): ValidatorFn {
    return (_: AbstractControl): ValidationErrors | null => {
      if (errorMessage) {
        return { customError: errorMessage };
      }
      return null;
    };
  }

  /**
   * @description Updates the form value for a question
   * @param { string } questionId The Id of question to update
   * @param { string } value The value that the question is to be updated with
   * @returns { void }
   */
  private updateFormValue(questionId: string, value: string): void {
    const formToPatch = this.form.get(questionId);
    formToPatch?.patchValue(value);
    formToPatch?.updateValueAndValidity();
  }

  /**
   * @description Adds the question to the group
   * @param { any } group Group the question is to be added to
   * @param { BaseQuestion } question Question to be added to group
   * @returns { void }
   */
  addQuestionFormControl<T>(
    group: CustomFormControl,
    question: Question<T>,
  ): void {
    group[question.id] = new FormControl(
      { value: question.value ?? '', disabled: !question.enabled },
      question.required ? Validators.required : null,
    );
    group[question.id].title = question.title;
    group[question.id].id = question.id;
  }

  /**
   * @description Execution of action from step submission component. View/Proceed/Exit
   * @param { StepSubmittedAction } action Action to perform from step submission component
   * @returns { void }
   */
  stepSubmissionAction(action: StepSubmittedAction): void {
    switch (action) {
      case StepSubmittedAction.Proceed:
        this.updateFlow(this.flow!);
        this.currentShowComponentEnum = AvailableComponent.StepDetail;
        break;
      case StepSubmittedAction.View:
        this.currentShowComponentEnum = AvailableComponent.StepDetail;
        break;
      case StepSubmittedAction.Exit:
        this.saveAndExit();
        break;
    }
  }

  /**
   * @description Updates the flow for the stepper.
   * @param { IFlow } flow Flow of the form
   * @param { boolean } changeStep Whether to change the step or stay on same one to view it
   * @param { boolean } loadFormGroup Whether we want to reload the form controls on the page
   * @returns { void }
   */
  public updateFlow(
    flow: IFlow,
    changeStep: boolean = true,
    loadFormGroup: boolean = true,
    stepToUpdateId: string | undefined = undefined,
  ): void {
    this.flow$ = of(flow);
    this.flow = flow;
    this.formGuid = flow.id;

    if (changeStep) {
      const displayStepId = stepToUpdateId ?? flow.currentStepId;
      this.stepIndex = flow.steps.findIndex(
        (step) => step.id === displayStepId,
      );
      if (this.stepIndex > -1) {
        this.onSelectedStepChange(flow.steps[this.stepIndex], loadFormGroup);
      }
    }
  }

  /**
   * @description Updates the selected step and optionally the form controls
   * @param { IStep } step The step to updated the selected step to
   * @param { boolean } loadFormGroup Whether we want to reload the form controls on the page
   * @returns { void }
   */
  onSelectedStepChange(step?: IStep, loadFormGroup: boolean = true): void {
    this.cancelReopenMode();

    this.selectedStep = step;
    if (this.selectedStep) {
      this.currentShowComponentEnum = AvailableComponent.StepDetail;
      this.lastStep =
        this.selectedStep.id ===
        this.flow!.steps[this.flow!.steps?.length - 1].id;
      this.updateCurrentTime(this.selectedStep);
    }
    if (loadFormGroup) {
      if (this.form) {
        this.clearFormValidatorsOfErrors(this.form);
      }
      this.loadFormGroup();
      if (this.stepDetailComponent) {
        this.stepDetailComponent.updateFormGroupForValidationSummary(this.form);
      }
    }
  }

  onStepEvent(stepEvent: StepEvent) {
    this._flowService
      .submitStep(
        new SubmitStepRequest(
          this.formGuid!,
          stepEvent.stepId,
          stepEvent.ruleTriggerEvent,
          stepEvent.files,
        ),
      )
      .subscribe((response: PatchFlowEventResponse) => {
        this.isAffectedQuestionsWithErrors = false;
        // Data validation fires on step submitted, this is to check the result.
        // If any affected question, step is not submitted and the questions are updated with error.
        if (
          response.affectedQuestions &&
          response.affectedQuestions.length > 0
        ) {
          this.updateFormGroup(response.affectedQuestions, response.flow);
        }
        if (!this.isAffectedQuestionsWithErrors) {
          // Step was completed without any validation error clear form of errors
          this.clearFormValidatorsOfErrors(this.form);
          this.updateFlow(response.flow, true, false, stepEvent.stepId);

          this.currentShowComponentEnum =
            stepEvent.ruleTriggerEvent === RuleTriggerEvent.StepCompleting
              ? AvailableComponent.StepSubmitted
              : AvailableComponent.StepDetail;
        }
      });
  }

  /**
   * @description Remove validators from form
   * @param { FormGroup } formGroup FormGroup to be clearer of errors
   * @returns { void }
   */
  clearFormValidatorsOfErrors(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((formControlName) => {
      const formControl = this.form.get(formControlName);
      formControl?.clearValidators();
      formControl?.updateValueAndValidity();
    });
  }

  onFormRuleEvent(ruleTriggerEvent: RuleTriggerEvent) {
    this._flowService
      .patchFlow(new PatchFlowRequest(this.formGuid!, ruleTriggerEvent))
      .subscribe((response: PatchFlowEventResponse) => {
        this.updateFlow(response.flow);
      });
  }

  onQuestionEvent(questionEvent: QuestionEvent) {
    const formControlToPatch = this.form.get(questionEvent.questionId);
    this._flowService
      .submitQuestion(
        new SubmitQuestionRequest(
          this.formGuid!,
          questionEvent.questionId,
          questionEvent.ruleTriggerEvent,
          questionEvent.newValue,
          questionEvent.files,
        ),
      )
      .subscribe((response: PatchFlowEventResponse) => {
        if (
          !response.affectedQuestions?.find(
            (af) => af.questionId == questionEvent.questionId,
          )
        ) {
          // Since custom error is only validation allowed, its only one that needs to be removed on return
          this.removeOnlyCustomError(formControlToPatch);
        }
        // Affected questions that don't have error messages can have validators removed if exist
        response.affectedQuestions?.forEach((affectedQuestion) => {
          if (!affectedQuestion.errorMessage) {
            const affectedControlToPatch = this.form.get(
              affectedQuestion.questionId,
            );
            this.removeOnlyCustomError(affectedControlToPatch);
          }
        });
        if (questionEvent.files) {
          this.updateUploadedFileId(
            response.flow,
            response.affectedQuestions!,
            questionEvent.questionId,
          );
        }
        this.updateFormGroup(response.affectedQuestions, response.flow);
        this.updateFlow(response.flow, false);
      });
  }

  /**
   * @description Add new uploaded file Id to affected question to value is patched in the form.
   * @param { IFlow } flow The update flow after question has changed
   * @param { AffectedQuestion[] } affectedQuestions Current list of affected questions
   * @param { string } questionId The id of question changed
   * @returns { void }
   */
  updateUploadedFileId(
    flow: IFlow,
    affectedQuestions: AffectedQuestion[],
    questionId: string,
  ): void {
    const updatedQuestion = flow.steps
      ?.find((step) => step.id == this.selectedStep?.id)
      ?.questions?.find((question) => question.id == questionId) as
      | FileUpload
      | undefined;

    if (updatedQuestion) {
      const uploadAffectedQuestion = new AffectedQuestion();
      uploadAffectedQuestion.questionId = questionId;
      uploadAffectedQuestion.newValue = updatedQuestion.value;
      uploadAffectedQuestion.actions = [AffectedQuestionAction.QuestionUpdate];
      affectedQuestions.push(uploadAffectedQuestion);
    }
  }

  /**
   * @description Remove all validators except required.
   * @param { AbstractControl<any> } formControlToPatch The control to remove validators on
   * @returns { void }
   */
  removeOnlyCustomError(formControlToPatch: AbstractControl<any> | null): void {
    const formValidators = (formControlToPatch as ControlWithValidators)
      ?._rawValidators;
    // If no validators exist return
    if (!formValidators) {
      return;
    }
    const filteredFormValidators = formValidators.filter(
      (x) => x.name == 'required',
    );
    this.clearValidationMessage(formControlToPatch);
    formControlToPatch?.setValidators(filteredFormValidators);
    formControlToPatch?.updateValueAndValidity();
  }

  /**
   * @description Clears error from control, and remove validators
   * @param { AbstractControl<any> } formControlToPatch The control to remove validators on
   * @returns { void }
   */
  clearValidationMessage(
    formControlToPatch: AbstractControl<any> | null,
  ): void {
    formControlToPatch?.setErrors(null);
    formControlToPatch?.clearValidators();
    formControlToPatch?.updateValueAndValidity();
  }

  onTableEvent(tableEvent: PatchFlowTableEventResponse): void {
    this.updateStepFromFlow(tableEvent.flow);

    this.updateFormGroup(tableEvent.affectedQuestions, tableEvent.flow);

    // Get the table from the response
    let table = tableEvent.flow.steps
      ?.find((response) => response.id == this.selectedStep?.id)
      ?.questions?.find(
        (question) => question.id == tableEvent.tableQuestion.id,
      ) as TableQuestion;

    switch (tableEvent.operationType) {
      case TableOperationType.DuplicateRow:
      case TableOperationType.AddRow:
        for (const tableColumn of table.tableColumns ?? []) {
          let tableCell = tableColumn.cells[tableColumn.cells.length - 1];

          this.addTableRow(
            tableColumn.headerQuestion,
            tableCell,
            this.form.controls as CustomFormControl,
          );
        }
        break;
      case TableOperationType.RemoveRow:
        for (const tableColumn of tableEvent.tableQuestion.tableColumns ?? []) {
          for (const tableCell of tableColumn.cells ?? []) {
            const cellExistsInNewTable = table.tableColumns!.some(
              (newTableColumn) =>
                newTableColumn.cells.some(
                  (newTableCell) => newTableCell.id === tableCell.id,
                ),
            );

            if (!cellExistsInNewTable) {
              this.form.removeControl(tableCell.id);
            }
          }
        }
        break;
      default:
        break;
    }
  }

  /**
   * @description Gets the current selected step and goes to the step after that
   * @returns { void }
   */
  goNextStep(): void {
    this.stepIndex = this.flow!.steps.findIndex(
      (step) => step.id === this.selectedStep?.id,
    );
    let currentStepIndex = this.stepIndex;
    this.onSelectedStepChange(this.flow!.steps[++currentStepIndex]);
  }

  /**
   * @description Checks if the form has been submitted and gets correct text for button
   * @returns { string } The text to display on the exit button
   */
  get exitBtnText(): string {
    return !this.flow?.isFlowCompleted &&
      this.selectedStep?.isActive &&
      !this.selectedStep?.isReadOnly
      ? 'Save & Exit'
      : 'Exit';
  }

  /**
   * @description Opens form summary view
   * @returns { void }
   */
  onSummaryClick(): void {
    this.currentShowComponentEnum = AvailableComponent.FormSummary;
    this.collapseStepper();
    this.onSelectedStepChange();
  }

  stepFeedbackAction(stepFeedback: Feedback) {
    this._flowService
      .submitStepFeedback(
        this.formGuid!,
        this.selectedStep!.id!,
        new StepFeedbackRequest(stepFeedback),
      )
      .subscribe(() => {});
  }

  onStepNoteEvent(stepNote: Note) {
    this._flowService
      .submitStepNote(
        this.formGuid!,
        this.selectedStep!.id!,
        new StepNoteRequest(stepNote.text, stepNote.createdDate, stepNote.id),
      )
      .subscribe(() => {});
  }

  addFeedback(feedbackEvent: AddFeedbackEvent): void {
    this.feedbackPanelData = { ...feedbackEvent };
    this.reopenMode = true;
    this.archiveQuestionComments();
  }

  changeComponentView(componentToShow: AvailableComponent): void {
    this.currentShowComponentEnum = componentToShow;
  }

  getWorkflowHistoryEventList(): void {
    this._flowService
      .getFormHistory(this.formGuid!)
      .pipe(take(1))
      .subscribe((response: FormHistory) => {
        this.allStepsTitles = new Map(Object.entries(response.allStepsTitles));
        this.flowEvents = response.events;
      });
  }

  changeDueDate(date: Date): void {
    this.feedbackPanelData!.dueDate = date;
  }

  confirmReopen(reopen: boolean): void {
    if (reopen) {
      // Set dueDate to end of day
      this.feedbackPanelData?.dueDate.setHours(23, 59, 59, 999);

      this._flowService
        .reopenStep(
          this.formGuid!,
          this.selectedStep!.id!,
          new StepReopenRequest(
            this.feedbackPanelData!.dueDate,
            this.extractUpdatedQuestionComments(),
          ),
        )
        .subscribe((response: IFlow) => {
          this.updateFlow(response, true, true, response.currentStepId);
          this.cancelReopenMode();
        });
    } else {
      this.cancelReopenMode();
      this.restoreArchivedQuestionComments();
    }
  }

  confirmFormReopen(formReopenRequest: FormReopeningData): void {
    this._flowService
      .reopenForm(
        this.formGuid!,
        new FormReopenRequest(
          formReopenRequest.stepToReopen,
          formReopenRequest.reasonForReopening,
        ),
      )
      .subscribe((response: IFlow) => {
        this.updateFlow(response, true, true, response.currentStepId);
      });
  }

  cancelReopenMode(): void {
    this.feedbackPanelData = undefined;
    this.reopenMode = false;
  }

  private restoreCommentToQuestion(question: BaseQuestion) {
    question.commentThread = this.archivedQuestionComments.find(
      (archivedQuestion) => archivedQuestion?.quetionId === question.id,
    );

    // Recursively handle Section type questions
    if (question.questionType === QuestionType.Section) {
      (question as Section).questions?.forEach((childQuestion) =>
        this.restoreCommentToQuestion(childQuestion),
      );
    }
  }

  restoreArchivedQuestionComments() {
    this.selectedStep?.questions.forEach((question) =>
      this.restoreCommentToQuestion(question),
    );
    this.archivedQuestionComments = []; // Clear the archived comments after restoring
  }

  private archiveCommentFromQuestion(question: BaseQuestion) {
    if (question.commentThread) {
      this.archivedQuestionComments.push(
        new ArchivedComments(
          question.id,
          question.commentThread.reason,
          question.commentThread.comments,
          question.commentThread.isAddressed,
          question.commentThread.otherReason,
        ),
      );
      question.commentThread = undefined;
    }

    // Recursively handle Section type questions
    if (question.questionType === QuestionType.Section) {
      (question as Section).questions?.forEach((childQuestion) =>
        this.archiveCommentFromQuestion(childQuestion),
      );
    }
  }

  archiveQuestionComments() {
    this.archivedQuestionComments = [];

    this.selectedStep?.questions.forEach((question) => {
      this.archiveCommentFromQuestion(question);
    });
  }

  private extractCommentsFromQuestion(
    question: BaseQuestion,
    threadCreationDate: Date,
  ): CreateCommentThreadDto[] {
    let comments: CreateCommentThreadDto[] = [];

    if (question.commentThread) {
      const updatedComments = question.commentThread.comments.map(
        (comment) => ({
          ...comment,
          timeStamp: threadCreationDate,
        }),
      );

      comments.push({
        ...question.commentThread,
        questionId: question.id,
        comments: updatedComments,
      } as CreateCommentThreadDto);
    }

    if (
      question.questionType === QuestionType.Section &&
      (question as Section).questions
    ) {
      comments = comments.concat(
        (question as Section).questions!.flatMap((subQuestion) =>
          this.extractCommentsFromQuestion(subQuestion, threadCreationDate),
        ),
      );
    }

    return comments;
  }

  extractUpdatedQuestionComments(): CreateCommentThreadDto[] {
    if (!this.selectedStep?.questions) {
      return [];
    }
    const threadCreationDate = new Date();
    return this.selectedStep.questions.flatMap((question) =>
      this.extractCommentsFromQuestion(question, threadCreationDate),
    );
  }

  onStepAttachmentEvent(stepAttachmentEvent: StepAttachmentEvent): void {
    switch (stepAttachmentEvent.eventType) {
      case StepAttachmentAction.Upload:
        this._flowService
          .stepAttachmentUpload(
            this.formGuid!,
            stepAttachmentEvent.stepId,
            new StepAttachmentUploadRequest(stepAttachmentEvent.files!),
          )
          .subscribe((response: IFlow) => {
            this.updateFlow(response, true, false, this.selectedStep!.id);
          });
        break;
      case StepAttachmentAction.Delete:
        this._flowService
          .stepAttachmentDelete(
            this.formGuid!,
            stepAttachmentEvent.stepId,
            stepAttachmentEvent.attachmentId!,
          )
          .subscribe((response: IFlow) => {
            this.updateFlow(response, true, false, this.selectedStep!.id);
          });
        break;
    }
  }

  toggleStepper(): void {
    this.isSideStepperExpanded = !this.isSideStepperExpanded;
    if (this.isSideStepperExpanded) this._scrollLockService.lockScroll();
    else this._scrollLockService.unlockScroll();
  }

  collapseStepper(): void {
    this.isSideStepperExpanded = false;
    this._scrollLockService.unlockScroll();
  }

  onSideStepperEvent(event: boolean): void {
    this.isSideStepperExpanded = event;
    this._scrollLockService.unlockScroll();
  }

  /**
   * @description Updates the selected step with the questions from the flow
   * @param flow Flow of the form
   * @returns { void }
   */
  updateStepFromFlow(flow: IFlow): void {
    this.selectedStep!.questions = flow.steps?.find(
      (response) => response.id == this.selectedStep?.id,
    )!.questions;
  }

  get canShowStepper(): boolean {
    return (
      !!this.selectedStep ||
      this.currentShowComponentEnum === AvailableComponent.FormSummary ||
      this.currentShowComponentEnum === AvailableComponent.WorkflowHistory
    );
  }

  get canShowSummaryActive(): boolean {
    return [
      AvailableComponent.FormSummary,
      AvailableComponent.WorkflowHistory,
    ].includes(this.currentShowComponentEnum);
  }

  onFormCancelEvent(formCancelEvent: FormCancelEvent): void {
    this._flowService.flowDelete(formCancelEvent.formId).subscribe(() => {});

    this._uriService.redirectToFlowFormaFormsList();
  }

  updateCurrentTime(selectedStep: IStep): void {
    if (selectedStep.dueDate) {
      this.currentTime = new Date();
      this.currentTimeUpdateInterval = setInterval(() => {
        this.currentTime = new Date();
      }, 60000);
    } else {
      clearInterval(this.currentTimeUpdateInterval);
    }
  }

  get getLogo() {
    return this.flow?.logo ?? this.defaultLogoImage;
  }
}

export interface ControlWithValidators extends FormControl {
  _rawValidators?: any[];
}

export enum AvailableComponent {
  FormSummary,
  WorkflowHistory,
  StepDetail,
  StepSubmitted,
  None,
}

export interface CustomFormControl {
  [key: string]: {
    title?: string;
    id?: string;
  } & FormControl;
}
