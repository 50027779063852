import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ButtonSize,
  ButtonType,
} from '../../../visual-components/components/ff-button/ff-button.component';
import { AvailableComponent } from '../form/form.component';
import { EventType, FlowEvent, FlowStepEvent } from '../../../models/FlowEvent';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'workflow-history',
  templateUrl: './workflow-history.component.html',
  styleUrls: ['./workflow-history.component.scss'],
})
export class WorkflowHistoryComponent implements OnChanges {
  @Input() flowTitle!: string;
  @Input() flowFormTitle!: string;
  @Input() flowEvents: FlowEvent[] = [];
  @Input() allStepsTitles: Map<string, string> = new Map<string, string>();

  @Output() changeComponentView = new EventEmitter<AvailableComponent>();

  flowEventsOrderEnum: SortType = SortType.Descending;
  displayedSortOptionEnum: SortType = SortType.Ascending;
  buttonTypeEnum: typeof ButtonType = ButtonType;
  buttonSizeEnum: typeof ButtonSize = ButtonSize;
  buttonFilterEnum: ButtonType = ButtonType.Secondary;

  copyFlowEvents: FlowEvent[] = [];
  acopyFlowEvents: FlowEvent[] = [];
  showFilter: boolean = false;
  actionOptions: string[] = [
    ...Object.values(FlowEventName),
    ...Object.values(StepEventName),
  ];

  public form = new FormGroup({
    action: new FormControl(''),
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    user: new FormControl([] as string[]),
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['flowEvents']) {
      this.flowEvents.sort((a, b) => {
        return (
          new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()
        );
      });
      this.flowEvents.reverse();
      this.copyFlowEvents = [...this.flowEvents];
    }
  }

  returnBack(): void {
    this.changeComponentView.emit(AvailableComponent.FormSummary);
  }

  sortEvents(sortOrder: SortType): void {
    this.copyFlowEvents.reverse();
    this.flowEventsOrderEnum = sortOrder;
    this.displayedSortOptionEnum =
      sortOrder === SortType.Ascending
        ? SortType.Descending
        : SortType.Ascending;
  }

  filterAndSortEvents(): void {
    this.changeFilterColor();

    this.copyFlowEvents = [...this.flowEvents].filter(
      (event) =>
        this.filterByAction(event) &&
        this.filterByDate(event) &&
        this.filterByUser(event),
    );

    if (this.flowEventsOrderEnum !== SortType.Descending)
      this.copyFlowEvents.reverse();
  }

  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  getEventUser(event: FlowEvent): string {
    return event.userDisplayName;
  }

  getEventText(event: FlowEvent): string {
    if ((event as FlowStepEvent).stepId) {
      return this.mapEventTypeToEventName(event.eventType, StepEventName);
    }
    return this.mapEventTypeToEventName(event.eventType, FlowEventName);
  }

  getStepName(event: FlowEvent): string {
    if ((event as FlowStepEvent).stepId) {
      const stepId = (event as FlowStepEvent).stepId!;
      return this.allStepsTitles.get(stepId) ?? '';
    }
    return '';
  }

  getEventIcon(event: FlowEvent): string {
    if ((event as FlowStepEvent).stepId) {
      return this.mapEventTypeToEventIcon(event.eventType, StepEventIcon);
    }
    return this.mapEventTypeToEventIcon(event.eventType, FlowEventIcon);
  }

  changeFilterColor(): void {
    this.buttonFilterEnum =
      this.form.controls.action.value?.length ||
      this.form.controls.startDate.value !== '' ||
      this.form.controls.endDate.value !== ''
        ? ButtonType.Primary
        : ButtonType.Secondary;
  }

  getEventUsers(): string[] {
    return [...new Set(this.flowEvents.map((event) => event.userDisplayName))];
  }

  private filterByAction(event: FlowEvent): boolean {
    const actionValue = this.form.controls.action.value;
    if (actionValue && actionValue.length > 0) {
      return actionValue.includes(
        (event as FlowStepEvent).stepId
          ? this.mapEventTypeToEventName(event.eventType, StepEventName)
          : this.mapEventTypeToEventName(event.eventType, FlowEventName),
      );
    }
    return true;
  }

  private filterByDate(event: FlowEvent): boolean {
    const startDate = this.form.controls.startDate.value;
    const endDate = this.form.controls.endDate.value;
    return (
      (!startDate ||
        new Date(event.eventDate).getTime() >= new Date(startDate).getTime()) &&
      (!endDate ||
        new Date(event.eventDate).getTime() <= new Date(endDate).getTime())
    );
  }

  private filterByUser(event: FlowEvent): boolean {
    const users = this.form.controls.user.value;
    if (!users || users.length === 0) {
      return true;
    }

    return users.includes(event.userDisplayName);
  }

  private mapEventTypeToEventName(
    eventType: EventType,
    eventNames: Record<string, string>,
  ): string {
    return eventNames[eventType];
  }

  private mapEventTypeToEventIcon(
    eventType: EventType,
    eventIcons: Record<string, string>,
  ): string {
    return eventIcons[eventType];
  }
}

export enum FlowEventIcon {
  Started = 'bi-plus-circle-dotted',
  Completed = 'bi-check-circle-fill text-success',
}

export enum StepEventIcon {
  Started = 'bi-plus-square-dotted',
  Submitted = 'bi-check-square',
  Saved = 'bi-save',
  Delegated = 'bi-box-arrow-in-down-right',
  ReOpen = 'bi-reply-all-fill text-warning',
}

export enum StepEventName {
  Started = 'Step started',
  Submitted = 'Step completed',
  Saved = 'Step saved',
  Delegated = 'Delegated',
  ReOpen = 'Reopened',
  Comment = 'Comments',
}

export enum FlowEventName {
  Started = 'Form started',
  Completed = 'Form submitted',
}

export enum SortType {
  Ascending = 'Newest first',
  Descending = 'Oldest first',
}
