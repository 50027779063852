import { Component } from '@angular/core';
import { NgControl } from '@angular/forms';

import { FFDateAndTimeComponentBase } from '../ff-base/ff-dateAndTime-component-base';

@Component({
  selector: 'ff-date-and-time',
  templateUrl: './ff-date-and-time.component.html',
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFDateAndTimeComponent extends FFDateAndTimeComponentBase {
  override formatDate(value: string | null): string | null {
    if (!value) {
      return null;
    }

    if (!this.isValidDateTime(value))
      throw new Error('Invalid date and time: ' + value);

    const formattedDate = this.datePipe.transform(value, 'yyyy-MM-dd HH:mm');
    return formattedDate == '' ? null : formattedDate;
  }
}
