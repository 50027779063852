import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { FFInputComponentBase } from '../ff-base/ff-input-component-base';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'ff-textarea',
  templateUrl: './ff-textarea.component.html',
  styleUrls: ['./ff-textarea.component.scss'],
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFTextareaComponent
  extends FFInputComponentBase
  implements OnInit, AfterViewInit
{
  @Input() numberOfLines?: number;
  @Input() numberOfColumns?: number;
  @Input() autoSize?: boolean;
  @Input() readOnly: boolean = false;
  @Input() maxlength?: any;

  @ViewChild('ffTextarea', { static: false }) ffTextarea!: ElementRef;

  textareaMaxHeight!: string;
  textareaMinHeight!: string;

  override ngOnInit(): void {
    super.ngOnInit();
    this.formControl.patchValue(this.control.value);
  }

  constructor(
    protected ngControl: NgControl,
    private cd: ChangeDetectorRef,
  ) {
    super(ngControl);
  }

  ngAfterViewInit() {
    const textareaStyle = this.getTextareaStyle(
      this.ffTextarea.nativeElement as HTMLTextAreaElement,
    );

    // calculate min height
    const rows =
      this.numberOfLines && this.numberOfLines > 3 ? this.numberOfLines : 3;
    this.textareaMinHeight =
      this.textareaHeightCalculation(
        textareaStyle.lineHeight,
        rows,
        textareaStyle.paddingY,
        textareaStyle.borderWidthY,
      ) + 'px';

    // calculate max height
    const lineCount = this.textareaLineCount(
      this.ffTextarea.nativeElement.scrollHeight,
      textareaStyle.paddingY,
      textareaStyle.lineHeight,
    );
    this.textareaMaxHeight =
      this.textareaHeightCalculation(
        textareaStyle.lineHeight,
        lineCount,
        textareaStyle.paddingY,
        textareaStyle.borderWidthY,
      ) + 'px';

    this.cd.detectChanges();
  }

  onChangeTextareaEvent(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    this.textareaMaxHeightSetter(textarea);
    this.onChangeEvent(textarea.value);
  }

  /**
   * @description Used to set the max height of the textarea.
   * If the textarea height is less than the saved height,
   * the saved height will be used to set the height of the textarea
   * @param { HTMLTextAreaElement } textarea The textarea element
   * @returns { void } The return type description
   */
  textareaMaxHeightSetter(textarea: HTMLTextAreaElement): void {
    // save the current height of the textarea
    const savedHeight = textarea.offsetHeight;

    // set the height of the textarea to the minimum height
    textarea.style.height = this.textareaMinHeight;

    // calculate new height of the textarea
    const coponentComputedStyle: FFTextareaStyle =
      this.getTextareaStyle(textarea);
    const lineCount = this.textareaLineCount(
      textarea.scrollHeight,
      coponentComputedStyle.paddingY,
      coponentComputedStyle.lineHeight,
    );

    // calculate and set the max height of the textarea
    const textareaMaxHeightNumber = this.textareaHeightCalculation(
      coponentComputedStyle.lineHeight,
      lineCount,
      coponentComputedStyle.paddingY,
      coponentComputedStyle.borderWidthY,
    );
    this.textareaMaxHeight = `${textareaMaxHeightNumber}px`;

    // set the height of the textarea to the saved height or the max height
    textarea.style.height =
      textareaMaxHeightNumber < savedHeight
        ? `${textareaMaxHeightNumber}px`
        : `${savedHeight}px`;
  }

  /**
   * @description Used to get the textarea style: lineHeight, paddingY and borderWidthY
   * @param { HTMLTextAreaElement } textarea The textarea element
   * @returns { FFTextareaStyle } The component style
   */
  getTextareaStyle(textarea: HTMLTextAreaElement): FFTextareaStyle {
    const computedStyle = window.getComputedStyle(textarea);

    const textareaLineHeight: number = parseInt(computedStyle.lineHeight, 10);
    const textareaPaddingY: number =
      parseInt(computedStyle.paddingTop, 10) +
      parseInt(computedStyle.paddingBottom, 10);
    const borderY: number =
      parseInt(computedStyle.borderTopWidth, 10) +
      parseInt(computedStyle.borderBottomWidth, 10);

    return {
      lineHeight: textareaLineHeight,
      paddingY: textareaPaddingY,
      borderWidthY: borderY,
    };
  }

  /**
   * @description Used to calculate the textarea height
   * @param { number } lineHeight The line height of the textarea
   * @param { number } lineCount The line count of the textarea
   * @param { number } paddingY The Y padding of the textarea
   * @param { number } borderWidthY The border width of the textarea
   * @returns { number } The height of the textarea
   */
  textareaHeightCalculation(
    lineHeight: number,
    lineCount: number,
    paddingY: number,
    borderWidthY: number,
  ): number {
    const textareaHeight: number =
      lineHeight * lineCount + paddingY + borderWidthY;
    return textareaHeight;
  }

  /**
   * @description Used to calculate the textarea line count
   * @param { number } scrollHeight The scroll height of the textarea
   * @param { number } paddingY The Y padding of the textarea
   * @param { number } lineHeight The line height of the textarea
   * @returns { number } The line count of the textarea
   */
  textareaLineCount(
    scrollHeight: number,
    paddingY: number,
    lineHeight: number,
  ): number {
    return (scrollHeight - paddingY) / lineHeight;
  }
}

interface FFTextareaStyle {
  lineHeight: number;
  paddingY: number;
  borderWidthY: number;
}
