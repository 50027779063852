import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FFInputComponentBase } from '../ff-base/ff-input-component-base';
import { ListImageItem } from '../ff-upload-list-images/ff-upload-list-images.component';
import { FFUploadDragAndDropComponent } from '../ff-upload-drag-and-drop/ff-upload-drag-and-drop.component';
import { NgControl } from '@angular/forms';
import {
  FileExtensionPermissionType,
  FileUploadDetails,
  FileUploadStorageOptions,
  FileUploadStorageType,
} from 'src/app/models/FileUpload';
import { FileService } from 'src/app/common/services/file.service';

@Component({
  selector: 'ff-upload',
  templateUrl: './ff-upload.component.html',
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFUploadComponent
  extends FFInputComponentBase
  implements OnInit, OnChanges
{
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @ViewChild(FFUploadDragAndDropComponent)
  childComponent!: FFUploadDragAndDropComponent;

  @Input() allowMultiple = false;
  @Input() withPreview = false;
  @Input() withAnnotation = false;
  @Input() hideUploadButton? = false;
  @Input() hideUploadLink? = false;
  @Input() annotationColor: string = '#FF0000';
  @Input() files: File[] = [];
  @Input() fileSizeLimit?: number;
  @Input() readOnly = false;
  @Input() savedFiles: FileUploadDetails[] = [];
  @Input() storageOptions?: FileUploadStorageOptions;
  @Input() extensions?: string[];
  @Input() fileExtensionPermissionType?: FileExtensionPermissionType;

  @Output() onDelete: EventEmitter<number> = new EventEmitter<number>();

  uploadListImageItems: ListImageItem[] = [];

  constructor(
    protected ngControl: NgControl,
    private readonly _fileService: FileService,
  ) {
    super(ngControl);
  }

  override ngOnInit(): void {
    if (
      this.savedFiles.length > 0 &&
      this._fileService.isImageFileName(this.savedFiles[0].displayName!) &&
      this.files.length === 0
    ) {
      if (this.storageOptions?.type === FileUploadStorageType.Azure) {
        this._fileService
          .getAzureImage(
            this.savedFiles[0].fileUrl!,
            this.savedFiles[0].displayName!,
          )
          .subscribe((file) => this.updateFilesWithRetrievedBlobFile(file));
      } else if (
        this.storageOptions?.type === FileUploadStorageType.Sharepoint
      ) {
        this._fileService
          .getSharePointImage(
            this.savedFiles[0].displayName!,
            this.savedFiles[0].fileUrl!,
          )
          .subscribe((file) => this.updateFilesWithRetrievedBlobFile(file));
      }
    } else {
      this.updateUploadListImageItems();
    }
  }

  updateFilesWithRetrievedBlobFile(file: File | null): void {
    if (file) {
      this.files = [file];
      this.updateUploadListImageItems();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['files']) {
      this.updateUploadListImageItems();
    }
  }

  getUploadListFileItems(): FileUploadDetails[] {
    if (this.formControl.value) {
      return [this.formControl.value] as FileUploadDetails[];
    }
    return [];
  }

  handleFileInput($event: Event) {
    let files!: FileList | null;
    if ($event instanceof DragEvent) {
      files = $event.dataTransfer!.files;
    } else {
      files = ($event.target as HTMLInputElement).files;
    }
    if (files?.length) {
      this.onChangeEvent(files);
    }
  }

  /**
   * @description gets the allowed file extensions as string
   * @returns { string | undefined } string of allowed extensions
   */
  getAcceptableExtensions(): string | undefined {
    if (
      this.fileExtensionPermissionType === FileExtensionPermissionType.Allowed
    ) {
      return this.extensions?.join(', ');
    }
    return undefined;
  }

  onDrop($event: DragEvent): void {
    this.onChangeEvent($event);
  }

  updateUploadListImageItems(): void {
    this.uploadListImageItems = this.files
      ? this.files.map((file) => ({ fileName: file.name, image: file }))
      : [];
  }

  deleteListItem(index: number): void {
    if (this.fileInput == undefined && this.childComponent.fileInput) {
      this.childComponent.fileInput.nativeElement.value = '';
    } else {
      this.fileInput.nativeElement.value = '';
    }
    if (this.savedFiles.length > index && index >= 0) {
      const fileToDelete = this.savedFiles[index];
      this._fileService
        .deleteListItemFile(this.storageOptions!, fileToDelete.fileUrl!)
        .subscribe((result) => {
          if (result) {
            this.formControl.patchValue(null);
            this.files.splice(index, 1);
            this.updateUploadListImageItems();
            this.onDelete.emit(index);
          }
        });
    }
  }

  downloadFileItem(index: number): void {
    if (this.savedFiles.length > index && index >= 0) {
      if (this.storageOptions?.type == FileUploadStorageType.Sharepoint) {
        const savedLoc = this.savedFiles[index].fileUrl;
        this._fileService.downloadSharepointFile(
          this.savedFiles[index].displayName!,
          savedLoc!,
        );
      } else if (this.storageOptions?.type == FileUploadStorageType.Azure) {
        this._fileService.downloadAzureFile(
          this.savedFiles[index].fileUrl!,
          this.savedFiles[index].displayName!,
        );
      }
    }
  }
}
