<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>
  <ff-control-validation
    *ngIf="hasErrors"
    [errors]="control.errors"
    [label]="errorMessageSuffix || label"
  />

  <textarea
    #ffTextarea
    class="d-flex rounded-1 ffTextarea"
    [ngClass]="{
      'form-control': !readOnly,
      'form-control-plaintext': readOnly,
      'shadow-none': readOnly,
      'border-0': readOnly
    }"
    [placeholder]="placeHolder || ''"
    [readonly]="readOnly ? 'readonly' : null"
    [formControl]="formControl"
    [class.is-invalid]="hasErrors"
    [id]="id"
    (click)="('')"
    [rows]="numberOfLines"
    [cols]="numberOfColumns"
    [style.resize]="numberOfLines ? 'vertical' : 'none'"
    [style.min-height]="textareaMinHeight"
    [style.max-height]="textareaMaxHeight"
    [maxlength]="maxlength"
    (change)="onChangeTextareaEvent($event)"
  ></textarea>
</div>
