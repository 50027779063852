import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { QuestionEvent } from '../../steps/step-detail/step-detail.component';
import { BaseQuestion } from '../../../models/Question';
import { Section } from '../../../models/Section';
import { OnCommentRequest } from '../question-comment/question-comment.model';

@Component({
  selector: 'section-question',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css'],
})
export class SectionComponent implements OnInit {
  @Input() question!: BaseQuestion;
  @Input() form!: FormGroup;
  @Input() readOnly!: boolean;
  @Input() reopenMode: boolean = false;
  @Input() flowId!: string;
  @Output() questionEvent = new EventEmitter<QuestionEvent>();
  @Output() questionComment = new EventEmitter<OnCommentRequest>();

  section!: Section;

  ngOnInit(): void {
    this.section = this.question as Section;
  }

  onQuestionEvent(questionEvent: QuestionEvent): void {
    this.questionEvent.emit(questionEvent);
  }

  onQuestionComment(comment: OnCommentRequest): void {
    this.questionComment.emit(comment);
  }
}
