import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  TableCell,
  TableCellValue,
  TableQuestion,
} from '../../../../models/TableQuestion';
import { QuestionBaseComponent } from '../../question-base-component';
import { QuestionEvent } from '../../../steps/step-detail/step-detail.component';
import { Question } from '../../../../models/Question';

import { saveAs } from 'file-saver';

import {
  FlowService,
  PatchFlowEventResponse,
  PatchFlowTableEventResponse,
  RuleTriggerEvent,
  TableOperationRequest,
  TableOperationType,
} from 'src/app/common/services/flows/flow.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'table-question',
  templateUrl: './table-question.component.html',
  styleUrls: ['./table-question.component.scss'],
})
export class TableQuestionComponent
  extends QuestionBaseComponent<QuestionEvent>
  implements OnInit
{
  constructor(private _flowService: FlowService) {
    super();
  }

  tableViewMode: 'card' | 'grid' = 'grid';

  @Output() tableEvent = new EventEmitter<PatchFlowTableEventResponse>();
  public tableQuestion!: TableQuestion;
  public mappedRows: TableCell[][] = [];
  public mappedFooter: Question<TableCellValue>[] = [];
  public footerQuestions: Question<TableCellValue>[][] = [];

  public ngOnInit(): void {
    this.tableQuestion = this.question as TableQuestion;
    this.mapTable();

    let formControl = this.form.get(this.tableQuestion.id) as FormControl;

    if (formControl !== null) {
      this.tableViewMode = Object.hasOwn(formControl.value, 'tableViewMode')
        ? formControl.value.tableViewMode
        : this.tableViewMode;
    }
  }

  changeActiveView(view: string): void {
    this.form.get(this.tableQuestion.id)?.setValue({ tableViewMode: view });
  }

  private mapFooterForCardView(
    footerQuestions: Question<TableCellValue>[],
  ): void {
    for (const footer of footerQuestions ?? []) {
      this.mappedFooter.push(footer);
    }
  }

  private mapTable(): void {
    if (
      this.tableQuestion.tableColumns == null ||
      this.tableQuestion.tableColumns.length == 0
    ) {
      return;
    }

    for (
      let rowIndex = 0;
      rowIndex < this.tableQuestion.tableColumns[0].cells.length;
      rowIndex++
    ) {
      const mappedRow: TableCell[] = [];

      for (let column of this.tableQuestion.tableColumns) {
        let cell = column.cells[rowIndex];
        let questionInfo = {
          ...column.headerQuestion,
          enabled: column.headerQuestion.enabled,
          id: cell.id,
          required: rowIndex === 0 ? column.headerQuestion.required : false,
          showTitle: rowIndex === 0 ? column.headerQuestion.showTitle : false,
          value: cell.value ?? null,
        };

        // Push the cell to the row
        mappedRow.push({
          cellAnswer: cell,
          questionInfo: questionInfo,
        });
      }

      this.mappedRows.push(mappedRow);
    }

    // Map the footer
    for (let tableColumn of this.tableQuestion.tableColumns ?? []) {
      this.mapFooterForCardView(tableColumn.footerQuestions);
      this.footerQuestions.push(tableColumn.footerQuestions);
    }

    this.mappedRows = this.mappedRows.filter((entry) => entry !== undefined);
  }

  export(question: TableQuestion): void {
    this._flowService
      .generateTableCsv(this.flowId, question.id)
      .subscribe((file) => {
        if (file) {
          saveAs(file, question.title + '.csv');
        }
      });
  }

  addRow(): void {
    this._flowService
      .tableOperation(
        new TableOperationRequest(
          this.flowId,
          TableOperationType.AddRow,
          RuleTriggerEvent.QuestionUpdated,
          this.tableQuestion.id,
          1,
        ),
      )
      .subscribe((response: PatchFlowEventResponse) => {
        let newEvent = new PatchFlowTableEventResponse();
        newEvent.flow = response.flow;
        newEvent.affectedQuestions = response.affectedQuestions;
        newEvent.operationType = TableOperationType.AddRow;
        newEvent.tableQuestion = this.tableQuestion;

        this.tableEvent.emit(newEvent);
      });
  }

  onRowEvent(rowEvent: RowEvent): void {
    this._flowService
      .tableOperation(
        new TableOperationRequest(
          this.flowId,
          rowEvent.type,
          RuleTriggerEvent.QuestionUpdated,
          this.tableQuestion.id,
          rowEvent.index,
        ),
      )
      .subscribe((response: PatchFlowEventResponse) => {
        let newEvent = new PatchFlowTableEventResponse();
        newEvent.flow = response.flow;
        newEvent.affectedQuestions = response.affectedQuestions;
        newEvent.operationType = rowEvent.type;
        newEvent.tableQuestion = this.tableQuestion;

        this.tableEvent.emit(newEvent);
      });
  }

  updateQuestionValue(tableQuestionEvent: QuestionEvent): void {
    this._flowService
      .tableOperation(
        new TableOperationRequest(
          this.flowId,
          TableOperationType.QuestionUpdated,
          RuleTriggerEvent.QuestionUpdated,
          this.tableQuestion.id,
          tableQuestionEvent.newValue,
          tableQuestionEvent.questionId,
        ),
      )
      .subscribe((response: PatchFlowEventResponse) => {
        let newEvent = new PatchFlowTableEventResponse();
        newEvent.flow = response.flow;
        newEvent.affectedQuestions = response.affectedQuestions;
        newEvent.operationType = TableOperationType.QuestionUpdated;
        newEvent.tableQuestion = this.tableQuestion;
        this.tableEvent.emit(newEvent);
      });
  }
}

export class RowEvent {
  constructor(
    public index: number,
    public type: TableOperationType,
  ) {}
}
