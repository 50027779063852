<div [formGroup]="form">
  <ff-radio
    *ngIf="yesNo && !readOnly"
    [formControlName]="yesNo.id"
    [tooltip]="yesNo.description"
    [showTitle]="yesNo.showTitle"
    [enabled]="yesNo.enabled"
    [label]="yesNo.title"
    [id]="yesNo.id"
    [required]="yesNo.required!"
    [options]="yesNo.options"
    (OnChange)="change($event)"
    [horizontalLayout]="yesNo.horizontalLayout"
  >
    <ng-container *ngTemplateOutlet="commentIcon" />
  </ff-radio>
  <ff-textbox
    *ngIf="yesNo && readOnly"
    [tooltip]="yesNo.description"
    [label]="yesNo.title"
    [id]="yesNo.id"
    [required]="yesNo.required!"
    [showTitle]="yesNo.showTitle"
    [value]="yesNo.value"
    [showComment]="showComment"
    [enabled]="yesNo.enabled"
  >
    <ng-container *ngTemplateOutlet="commentIcon" />
  </ff-textbox>
</div>

<ng-template #commentIcon>
  <ng-content />
</ng-template>
