<!-- from https://primeng.org/multiselect#api.multiselect.templates.selectedItems -->
<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>
  <ff-control-validation
    *ngIf="hasErrors"
    [errors]="control.errors"
    [label]="label"
  />

  <div>
    <p-multiSelect
      [options]="options!"
      [formControl]="formControl"
      [inputId]="id!"
      [showHeader]="false"
      [placeholder]="placeHolder ?? ''"
      (onChange)="onChangeEvent($event)"
      (onPanelHide)="onHide()"
      display="chip"
      [class.ng-invalid]="hasErrors"
      [class.ng-dirty]="hasErrors"
    ></p-multiSelect>
  </div>
</div>
