import { Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'ff-context-menu',
  templateUrl: './ff-context-menu.component.html',
})
export class FFContextMenuComponent {
  @Input() itemsTemplate: any;
  @Input() itemsTemplateContext: any;

  @ViewChild('dropDown') dropDown: any;

  /**
   * @description Opens context menu
   * @returns { void }
   */
  open(): void {
    this.dropDown.open();
  }
}
