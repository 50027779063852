<div [formGroup]="form">
  <div *ngIf="personOrGroup && !readOnly">
    <ff-people-picker
      [formControlName]="personOrGroup.id"
      [tooltip]="personOrGroup.description"
      [label]="personOrGroup.title"
      [id]="personOrGroup.id"
      [required]="personOrGroup.required!"
      [enabled]="personOrGroup.enabled"
      [showTitle]="personOrGroup.showTitle"
      [value]="personOrGroup.value"
      [placeHolder]="'Enter username or email addresses'"
      (OnChange)="change($event)"
      [customSearchFunction]="GetPeopleOrGroupFromQuestion"
      [allowMultiple]="personOrGroup.allowMultiple"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </ff-people-picker>
  </div>
  <ff-textbox
    *ngIf="personOrGroup && readOnly"
    [tooltip]="personOrGroup.description"
    [label]="personOrGroup.title"
    [id]="personOrGroup.id"
    [required]="personOrGroup.required!"
    [showTitle]="personOrGroup.showTitle"
    [value]="personOrGroupReadOnlyValue"
    [showComment]="showComment"
    [enabled]="personOrGroup.enabled"
  >
    <ng-container *ngTemplateOutlet="commentIcon" />
  </ff-textbox>
</div>
<ng-template #commentIcon>
  <ng-content />
</ng-template>
