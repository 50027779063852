import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgControl } from '@angular/forms';
import { FFInputNumberComponent } from '../ff-input-number/ff-input-number.component';

@Component({
  selector: 'ff-input-number-slider',
  templateUrl: './ff-input-number-slider.component.html',
  host: {
    '(OnChange)': 'onChangeEvent($event.target.value)',
  },
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFInputNumberSliderComponent
  extends FFInputNumberComponent
  implements OnInit
{
  @Input() median?: number;
  @Input() form: FormGroup;

  constructor(public override control: NgControl) {
    super(control);
    this.form = new FormGroup({
      numberBox: new FormControl(),
      slider: new FormControl(),
    });
  }

  override ngOnInit() {
    super.ngOnInit();

    if (!this.formControl.value) {
      this.form.patchValue({
        numberBox: this.minimum,
        slider: this.minimum,
      });
    } else {
      this.form.patchValue({
        numberBox: this.formControl.value,
        slider: this.formControl.value,
      });
    }

    if (this.maximum != null && this.minimum != null) {
      let range = this.maximum - this.minimum;
      this.median = range / 2 + this.minimum;
    }
  }

  change($event: Event): void {
    const targetValue = Number(($event.target as HTMLInputElement).value);

    let newValue: number | string = targetValue;
    if (this.maximum != null && this.minimum != null) {
      newValue = Math.min(Math.max(targetValue, this.minimum), this.maximum);
    }

    newValue = newValue.toFixed(this.decimalPlaces);

    this.form.patchValue({
      slider: newValue,
      numberBox: newValue,
    });
  }
}
