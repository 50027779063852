import { Component } from '@angular/core';
import { NgControl } from '@angular/forms';

import { FFDateAndTimeComponentBase } from '../ff-base/ff-dateAndTime-component-base';

@Component({
  selector: 'ff-date-only',
  templateUrl: './ff-date-only.component.html',
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFDateOnlyComponent extends FFDateAndTimeComponentBase {
  override formatDate(value: string | null): string | null {
    if (!value) {
      return null;
    }

    if (!this.isValidDateTime(value)) throw new Error('Invalid date: ' + value);

    const formattedDate = this.datePipe.transform(value, 'yyyy-MM-dd');
    return formattedDate == '' ? null : formattedDate;
  }
}
