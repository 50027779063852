<ng-container>
  <div class="border p-4 shadow rounded-top">
    <div class="mb-3">
      <img [src]="logo" class="col-4" alt="flowforma logo" />
    </div>

    <div class="mb-3">
      <h5 class="fw-bold">{{ flowTitle }}</h5>

      <ff-text [text]="'QA_1234'"
        ><i class="bi bi-file-earmark-text me-1"></i
      ></ff-text>
    </div>

    <ff-message-divider
      [message]="title"
      [alertType]="alertTypeEnum.Success"
      [title]="'Delegated'"
      [subTitle]="'Step ' + stepIndex"
    />

    <div class="container pb-2">
      <h5 class="fw-bold">This step has been sent to</h5>
      <div class="row pb-2">
        <div class="col-12">
          <div class="d-flex justify-content-start align-items-center">
            <div *ngIf="!avatarImage" class="text-primary">
              <i class="bi bi-person-circle h5"></i>
            </div>
            <img
              *ngIf="avatarImage"
              class="img-fluid rounded-circle w-10"
              [src]="avatarImage"
              alt="image of a person"
            />

            <div class="bg-light ms-3 p-1 rounded fw-bold">
              {{ assignedTo }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <button
        type="button"
        (click)="toDashboard()"
        class="btn btn-primary w-100"
      >
        Go to dashboard
      </button>
    </div>
  </div>
  <div
    class="modal-footer-background-color rounded-bottom container d-flex flex-column justify-content-end align-items-end"
  >
    <div class="d-flex justify-content-end align-items-center w-100">
      <ff-text [text]="'Get more work done with'"></ff-text>
      <img class="col-3" alt="flowforma logo" [src]="defaultLogoImage" />
    </div>
    <a class="nav-link text-primary p-1">Find out more</a>
  </div>
</ng-container>
