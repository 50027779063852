<div [formGroup]="commentForm">
  <ff-radio formControlName="reason" [options]="options" [showTitle]="false" />
  <div class="pt-0 pb-2">
    <ff-input-text
      [hidden]="!showOtherReasonTextBox"
      formControlName="otherReason"
      [showTitle]="false"
      placeHolder="Please describe"
      [required]="showOtherReasonTextBox"
    />
  </div>
  <div>
    <ff-textarea
      formControlName="text"
      [showTitle]="false"
      [autoSize]="false"
      [maxlength]="100"
      placeHolder="Add Comments"
      (OnKeyUp)="keyUp($event)"
    ></ff-textarea>
    <p class="pt-1 fw-bold text-muted">
      {{ commentContent }}/{{ maxCharacterLimit }}
    </p>
  </div>
  <div class="mt-2 d-flex justify-content-end">
    <ff-button
      [buttonType]="buttonTypeEnum.Secondary"
      textValue="Cancel"
      [enabled]="true"
      [buttonSize]="buttonSizeEnum.Small"
      [buttonTooltip]="false"
      (OnClick)="close()"
    ></ff-button>
    <ff-button
      class="ms-1"
      [buttonType]="buttonTypeEnum.Primary"
      textValue="Save"
      [enabled]="true"
      [buttonSize]="buttonSizeEnum.Small"
      [buttonTooltip]="false"
      (OnClick)="save()"
    ></ff-button>
  </div>
</div>
