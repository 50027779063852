import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe, JsonPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MsalGuard,
  MsalBroadcastService,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalModule,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptor,
  MsalRedirectComponent,
} from '@azure/msal-angular';

import {
  NgbTooltip,
  NgbAccordionModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbTypeaheadModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './components/app.component/app.component';
import { VcShowcaseComponent } from './vc-showcase/vc-showcase.component';
import { HomeComponent } from './components/home/home.component';
import { FFButtonComponent } from './visual-components/components/ff-button/ff-button.component';
import {
  ChooseQuantityComponent,
  FFInputTextComponent,
} from './visual-components/components/ff-input/ff-input-text/ff-input-text.component';
import { FfFormContainerDirective } from './visual-components/directives/ff-form-container/ff-form-container.directive';
import { FFFormContainerComponent } from './visual-components/components/ff-form/ff-form-container/ff-form-container.component';
import { FFSelectComponent } from './visual-components/components/ff-select/ff-select/ff-select.component';
import { FFControlValidationComponent } from './visual-components/components/ff-validation/ff-control-validation/ff-control-validation.component';
import { FFValidationNavigationComponent } from './visual-components/components/ff-validation/ff-validation-navigation/ff-validation-navigation.component';
import { FormComponent } from './components/forms/form/form.component';
import { StepDetailComponent } from './components/steps/step-detail/step-detail.component';
import { QuestionSelectorComponent } from './components/questions/question-selector/question-selector.component';
import { SingleLineOfTextComponent } from './components/questions/single-line-of-text/single-line-of-text.component';
import { FFRadioComponent } from './visual-components/components/ff-radio/ff-radio.component';
import { ChoiceComponent } from './components/questions/choice/choice.component';
import { FFCheckboxComponent } from './visual-components/components/ff-checkbox/ff-checkbox.component';
import { YesNoComponent } from './components/questions/yes-no/yes-no.component';
import { FFSeparatorComponent } from './visual-components/components/ff-separator/ff-separator.component';
import { FFTextareaComponent } from './visual-components/components/ff-textarea/ff-textarea.component';
import { MultipleLinesOfTextComponent } from './components/questions/multiple-lines-of-text/multiple-lines-of-text.component';
import { ButtonQuestionComponent } from './components/questions/button-question/button-question.component';
import { SeparatorComponent } from './components/questions/separator/separator.component';
import { FFInputNumberComponent } from './visual-components/components/ff-input/ff-input-number/ff-input-number.component';
import { FFDateAndTimeComponent } from './visual-components/components/ff-date-and-time/ff-date-and-time.component';
import { DateAndTimeComponent } from './components/questions/date-and-time/date-and-time.component';
import { NumberComponent } from './components/questions/number/number.component';
import { FFInputNumberSliderComponent } from './visual-components/components/ff-input/ff-input-number-slider/ff-input-number-slider.component';
import { FFMessageDividerComponent } from './visual-components/components/ff-message-divider/ff-message-divider.component';
import { FFUploadListImageItemComponent } from './visual-components/components/ff-upload-list-image-item/ff-upload-list-image-item.component';
import { FFUploadListImagesComponent } from './visual-components/components/ff-upload-list-images/ff-upload-list-images.component';
import { FFTitleDescriptionComponent } from './visual-components/components/ff-title-description/ff-title-description.component';
import { FFUploadListFileItemComponent } from './visual-components/components/ff-upload-list-file-item/ff-upload-list-file-item.component';
import { FFUploadListFilesComponent } from './visual-components/components/ff-upload-list-files/ff-upload-list-files.component';
import { FFDateOnlyComponent } from './visual-components/components/ff-date-only/ff-date-only.component';
import { FFTimeOnlyComponent } from './visual-components/components/ff-time-only/ff-time-only.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FFMultiselectComponent } from './visual-components/components/ff-multiselect/ff-multiselect.component';
import { FFUploadComponent } from './visual-components/components/ff-upload/ff-upload.component';
import { FileUploadComponent } from './components/questions/file-upload/file-upload.component';
import { StepperComponent } from './components/steps/stepper/stepper.component';
import { SectionComponent } from './components/questions/section/section.component';

import { FFComponentsModule } from '@flowforma/ff-components';
import { LogoutGuard, UserService } from '@flowforma/core';
import { CurrencySymbolPipe } from './visual-components/Pipes/currency-symbol.pipe';
import { LocalesModule } from './Modules/locales/locales.module';
import { FFContextMenuComponent } from './visual-components/components/ff-context-menu/ff-context-menu.component';
import { TableQuestionComponent } from './components/questions/table/table-question/table-question.component';

import { FFImageAnnotationComponent } from './visual-components/components/ff-image-annotation/ff-image-annotation.component';
import { TableGridComponent } from './components/questions/table/table-grid/table-grid.component';
import { FFUploadDragAndDropComponent } from './visual-components/components/ff-upload-drag-and-drop/ff-upload-drag-and-drop.component';

import { FilterStepperPipe } from './components/steps/stepper/pipes/filter-stepper.pipe';
import { TableQuestionCardComponent } from './components/questions/table/table-question-card/table-question-card.component';
import { FFTextBoxComponent } from './visual-components/components/ff-textbox/ff-textbox.component';
import { StepSubmissionComponent } from './components/steps/step-submitted/step-submitted.component';
import { PersonOrGroupComponent } from './components/questions/person-or-group/person-or-group.component';
import { DelegateStepDialogComponent } from './components/steps/delegate-step-dialog/delegate-step-dialog.component';
import { AddAttachmentsDialogComponent } from './components/steps/add-attachments-dialog/add-attachments-dialog.component';
import { DelegateStepSuccessDialogComponent } from './components/steps/delegate-step-success-dialog/delegate-step-success-dialog.component';
import { FormReopeningConfirmationDialogComponent } from './components/forms/form-reopening-confirmation-dialog/form-reopening-confirmation-dialog.component';
import { QuestionCommentComponent } from './components/questions/question-comment/question-comment.component';
import { StepReopeningConfirmationDialogComponent } from './components/steps/step-reopening-confirmation-dialog/step-reopening-confirmation-dialog.component';
import { FFPeoplePickerComponent } from './visual-components/components/ff-people-picker/ff-people-picker.component';
import { FFTextComponent } from './visual-components/components/ff-text/ff-text.component';
import { FeedbackPanelComponent } from './components/forms/feedback-panel/feedback-panel.component';
import { AddNoteComponent } from './components/steps/add-note/add-note.component';
import { WorkflowHistoryComponent } from './components/forms/workflow-history/workflow-history.component';
import { FormSummaryComponent } from './components/forms/form-summary/form-summary.component';
import { FormNameComponent } from './components/forms/form-name/form-name.component';
import { QuestionCommentThreadComponent } from './components/questions/question-comment-thread/question-comment-thread.component';
import { StepRemarksComponent } from './components/steps/step-remarks/step-remarks.component';
import { UserRemarkComponent } from './components/steps/user-remark/user-remark.component';
import { StepAttachmentsComponent } from './components/steps/step-attachments/step-attachments.component';
import { FormCardComponent } from './components/forms/form-card/form-card.component';
import { RelativeTimePipe } from './common/pipes/relative-time.pipe';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FFConfirmationDialogComponent } from './visual-components/components/ff-confirmation-dialog/ff-confirmation-dialog.component';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './auth-config';
import { FFxApiInterceptor } from './common/interceptors/ffx-api.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    VcShowcaseComponent,
    HomeComponent,
    FFButtonComponent,
    FFInputTextComponent,
    ChooseQuantityComponent,
    FfFormContainerDirective,
    FFFormContainerComponent,
    FFSelectComponent,
    FFControlValidationComponent,
    FFValidationNavigationComponent,
    FormComponent,
    StepDetailComponent,
    QuestionSelectorComponent,
    SingleLineOfTextComponent,
    ChoiceComponent,
    ButtonQuestionComponent,
    TableQuestionComponent,
    TableQuestionCardComponent,
    FFRadioComponent,
    FFCheckboxComponent,
    YesNoComponent,
    FFSeparatorComponent,
    FFTextareaComponent,
    MultipleLinesOfTextComponent,
    SeparatorComponent,
    DelegateStepDialogComponent,
    FFInputNumberComponent,
    FFMessageDividerComponent,
    FFUploadListFileItemComponent,
    FFUploadListFilesComponent,
    FFDateAndTimeComponent,
    FFDateOnlyComponent,
    FFTimeOnlyComponent,
    DateAndTimeComponent,
    NumberComponent,
    FFInputNumberSliderComponent,
    FFMessageDividerComponent,
    StepperComponent,
    FFUploadListImageItemComponent,
    FFUploadListImagesComponent,
    FFMultiselectComponent,
    FFUploadComponent,
    FileUploadComponent,
    FFTitleDescriptionComponent,
    SectionComponent,
    CurrencySymbolPipe,
    FFImageAnnotationComponent,
    FilterStepperPipe,
    FFContextMenuComponent,
    TableGridComponent,
    FFUploadDragAndDropComponent,
    FFTextBoxComponent,
    StepSubmissionComponent,
    DelegateStepSuccessDialogComponent,
    PersonOrGroupComponent,
    AddAttachmentsDialogComponent,
    FormReopeningConfirmationDialogComponent,
    QuestionCommentComponent,
    StepReopeningConfirmationDialogComponent,
    FFPeoplePickerComponent,
    FFTextComponent,
    FeedbackPanelComponent,
    AddNoteComponent,
    WorkflowHistoryComponent,
    FormSummaryComponent,
    FormNameComponent,
    QuestionCommentThreadComponent,
    StepRemarksComponent,
    UserRemarkComponent,
    StepAttachmentsComponent,
    FormCardComponent,
    RelativeTimePipe,
    FFConfirmationDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbTooltip,
    NgbAccordionModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbPopoverModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    MultiSelectModule,
    CheckboxModule,
    RadioButtonModule,
    AutoCompleteModule,
    FFComponentsModule,
    LocalesModule,
    NgbTypeaheadModule,
    JsonPipe,
    MsalModule,
    // ReactiveFormsModule.withConfig({
    //   callSetDisabledState: 'whenDisabledForLegacyCode',
    // })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FFxApiInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe,
    LogoutGuard,
    UserService,
  ],
  exports: [FilterStepperPipe],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
