import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableCellValue, TableCell } from '../../../../models/TableQuestion';
import { QuestionEvent } from '../../../steps/step-detail/step-detail.component';
import {
  RowEvent,
  TableQuestionComponent,
} from '../table-question/table-question.component';
import { TableOperationType } from 'src/app/common/services/flows/flow.service';
import { Question } from '../../../../models/Question';

@Component({
  selector: 'table-question-card',
  templateUrl: './table-question-card.component.html',
})
export class TableQuestionCardComponent
  extends TableQuestionComponent
  implements OnInit
{
  @Input() tableRow!: TableCell[];
  @Input() tableFooter!: Question<TableCellValue>[];
  @Input() index!: number;
  @Input() isFooter = false;
  @Input() isRowsFixed = false;
  @Input() isRowNumbersHidden = false;
  @Input() isRemoveButtonHidden = false;
  @Input() isReadOnly = false;
  @Input() enableRowDuplication = false;

  @Output() questionEvent = new EventEmitter<QuestionEvent>();
  @Output() rowEvent = new EventEmitter<RowEvent>();
  @Output() tableValueUpdateEvent = new EventEmitter<QuestionEvent>();

  public override ngOnInit(): void {
    // Always hide first question title
    if (!this.isFooter) {
      this.tableRow[0].questionInfo.showTitle = false;
    }
  }

  public onQuestionEvent(questionEvent: QuestionEvent): void {
    this.tableValueUpdateEvent.emit(questionEvent);
  }

  // Used to emit the event to the parent component
  rowRemove(index: number): void {
    this.rowEvent.emit(new RowEvent(index, TableOperationType.RemoveRow));
  }

  // Used to emit the event to the parent component
  rowDuplicate(index: number): void {
    this.rowEvent.emit(new RowEvent(index, TableOperationType.DuplicateRow));
  }
}
