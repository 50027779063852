<div ngbAccordion>
  <div ngbAccordionItem [collapsed]="false" [disabled]="!section.collapsible">
    <h2 ngbAccordionHeader>
      <button
        ngbAccordionButton
        class="mb-n3 px-0"
        [class.no-icon]="!section.collapsible"
      >
        <h5 class="mb-0">{{ section.title }}</h5>
      </button>
      <ff-separator></ff-separator>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-0">
        <ng-template>
          <div *ngFor="let question of section.questions">
            <question-selector
              class="mb-5"
              [question]="question"
              [form]="form"
              (questionEvent)="onQuestionEvent($event)"
              [readOnly]="readOnly"
              [reopenMode]="reopenMode"
              [flowId]="flowId"
            >
              <ng-content />
            </question-selector>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
