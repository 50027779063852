<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>
  <ff-control-validation
    *ngIf="hasErrors"
    [errors]="control.errors"
    [label]="errorMessageSuffix || label"
  />
  <div>
    <p-autoComplete
      [formControl]="formControl"
      [inputId]="id!"
      [minLength]="3"
      [delay]="200"
      [field]="'displayName'"
      (completeMethod)="search($event)"
      [multiple]="true"
      [forceSelection]="true"
      [suggestions]="options"
      (onSelect)="onSelect($event)"
      (onUnselect)="onUnselect($event)"
      [readonly]="isReadOnly"
      [class.ng-invalid]="hasErrors"
      [class.ng-dirty]="hasErrors"
      [placeholder]="placeHolder ?? ''"
    >
      <ng-template let-personOrGroup pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ personOrGroup.displayName }}</div>
          <div class="fs-7 fst-italic">
            {{ getFFxUserEmail(personOrGroup.loginName) }}
          </div>
        </div>
      </ng-template>
    </p-autoComplete>
  </div>
</div>
