import { Component, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FFInputComponentBase } from '../../ff-base/ff-input-component-base';
import { FormatType } from '../../../../models/FormatType';

@Component({
  selector: 'ff-input-number',
  templateUrl: './ff-input-number.component.html',
  styleUrls: ['./ff-input-number.component.scss'],
  host: {
    '(change)': 'onChangeEvent($event.target.value)',
  },
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFInputNumberComponent
  extends FFInputComponentBase
  implements OnInit
{
  @Input() culture?: string;
  @Input() decimalPlaces?: number;
  @Input() format?: FormatType;
  @Input() maximum?: number;
  @Input() minimum?: number;
  @Input() step?: number;
  @Input() isInputReadOnly?: boolean;

  protected readonly FormatType = FormatType;

  constructor(public override control: NgControl) {
    super(control);
  }

  applyValidation(): void {
    this.restrictRange();
    this.setDecimalPlaces();
  }

  restrictRange(): void {
    if (this.minimum != null && this.formControl.value < this.minimum) {
      this.formControl.setValue(this.minimum);
      return;
    }

    if (this.maximum && this.formControl.value > this.maximum) {
      this.formControl.setValue(this.maximum);
    }
  }

  setDecimalPlaces(): void {
    this.formControl.setValue(
      this.formControl.value.toFixed(this.decimalPlaces),
    );
  }
}
