<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>
  <ff-control-validation
    *ngIf="hasErrors"
    [errors]="control.errors"
    [label]="label"
  />

  <div class="d-flex align-items-center flex-column gap-2" [id]="id">
    <div
      class="d-flex gap-2 align-self-stretch"
      *ngFor="let option of options; let index = index"
    >
      <p-checkbox
        [value]="option"
        [formControl]="formControl"
        [inputId]="id! + index"
        [class.ng-invalid]="hasErrors"
        [class.ng-dirty]="hasErrors"
        (onChange)="onChangeEvent()"
      ></p-checkbox>
      <label
        class="form-check-label"
        [for]="id! + index"
        [class.text-danger]="hasErrors"
      >
        {{ option }}
      </label>
    </div>
  </div>
</div>
