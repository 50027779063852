import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'ff-control-validation',
  templateUrl: './ff-control-validation.component.html',
  styleUrls: ['./ff-control-validation.component.scss'],
})
export class FFControlValidationComponent {
  @Input()
  errors?: ValidationErrors | null;

  @Input()
  label?: string;
}
