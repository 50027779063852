import { Component, Input, OnInit } from '@angular/core';
import { NumberQuestion } from '../../../models/NumberQuestion';
import { QuestionBaseComponent } from '../question-base-component';

@Component({
  selector: 'number',
  templateUrl: './number.component.html',
})
export class NumberComponent
  extends QuestionBaseComponent<number>
  implements OnInit
{
  @Input() isCalculatedQuestion?: boolean = false;
  number!: NumberQuestion;

  ngOnInit(): void {
    this.number = this.question as NumberQuestion;
  }
}
