<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>
  <ff-control-validation
    *ngIf="hasErrors"
    [errors]="control.errors"
    [label]="errorMessageSuffix || label"
  />
  <!-- Even if addNullOption=false it still increases the iteration start. So it's not from 0 but from 1 without the null option -->
  <div class="dropdown">
    <select
      name="id"
      class="w-100 d-flex align-items-center flex-column py-1 px-2 rounded-1 form-control"
      [formControl]="formControl"
      [id]="id"
      (click)="('')"
      [multiple]="false"
      [size]="null"
      [class.is-invalid]="hasErrors"
    >
      <option *ngIf="addNullOption" [ngValue]="null"></option>
      <option
        *ngFor="let option of options"
        [ngValue]="option.value || option"
        [class.is-invalid]="hasErrors"
      >
        {{ option.displayName || option }}
      </option>
    </select>
  </div>
</div>
