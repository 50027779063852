import {
  Component,
  ComponentRef,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonSize, ButtonType } from '../ff-button/ff-button.component';
import { FFTextComponent } from '../ff-text/ff-text.component';

@Component({
  selector: 'ff-confirmation-dialog',
  templateUrl: './ff-confirmation-dialog.component.html',
})
export class FFConfirmationDialogComponent {
  @Input() title!: string;
  @Input() message!: string;
  @Input() confirmButtonText?: string = 'Confirm';
  @Input() cancelButtonText?: string = 'Back';
  @Input() ffTextComponent?: ComponentRef<FFTextComponent>;

  buttonTypeEnum: typeof ButtonType = ButtonType;
  buttonSizeEnum: typeof ButtonSize = ButtonSize;

  @ViewChild('container', { read: ViewContainerRef })
  container!: ViewContainerRef;

  constructor(public activeModal: NgbActiveModal) {}

  ngAfterViewInit() {
    if (this.ffTextComponent)
      this.container.insert(this.ffTextComponent.hostView);
  }

  confirm() {
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.dismiss();
  }
}
