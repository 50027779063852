import { Component } from '@angular/core';
import { FFInputMultipleValuesComponentBase } from '../ff-base/ff-input-multiple-values-base';

@Component({
  selector: 'ff-checkbox',
  templateUrl: './ff-checkbox.component.html',
})
export class FFCheckboxComponent extends FFInputMultipleValuesComponentBase {
  override ngOnInit() {
    super.ngOnInit();
    this.formControl.patchValue(this.control.value ?? this.defaultValues);
  }

  override onChangeEvent(): void {
    this.onChanged(this.formControl.value);

    if (this.control.valid) {
      this.OnChange.emit(this.formControl.value);
    }
  }
}
