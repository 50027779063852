import { Component, Input } from '@angular/core';
import { FFComponentBase } from '../ff-base/ff-component.base';

@Component({
  selector: 'ff-separator',
  templateUrl: './ff-separator.component.html',
  styleUrls: ['./ff-separator.component.scss'],
})
export class FFSeparatorComponent extends FFComponentBase {
  @Input() backgroundColor?: string;
}
