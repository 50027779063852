import { Directive, Input, OnInit } from '@angular/core';
import { FFInputComponentBase } from './ff-input-component-base';

@Directive()
export class FFInputMultipleValuesComponentBase
  extends FFInputComponentBase
  implements OnInit
{
  value: any[] = [];
  @Input() options?: any[];
  @Input() defaultValues?: any[];

  override ngOnInit(): void {
    super.ngOnInit();
    this.value = [
      ...new Set([
        ...(this.defaultValues ?? []),
        ...(this.control.value ?? []),
      ]),
    ];
  }

  override onChangeEvent(value: any): void {
    const index = this.value.indexOf(value);

    if (index > -1) {
      this.value = [
        ...this.value.slice(0, index),
        ...this.value.slice(index + 1),
      ];
    } else {
      this.value = [...this.value, value];
    }

    this.onChanged(this.value);

    if (this.control.valid) {
      this.OnChange.emit(this.value);
    }
  }

  isChecked<T>(option: T): boolean {
    return this.value.includes(option) ?? false;
  }
}
