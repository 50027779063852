<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>
  <ff-control-validation
    *ngIf="hasErrors"
    [errors]="control.errors"
    [label]="label"
  />

  <div class="input-group mb-3">
    <div class="input-group-prepend" *ngIf="format === FormatType.Currency">
      <span class="input-group-text">{{ culture ?? "" | currencySymbol }}</span>
    </div>
    <input
      type="number"
      class="form-control d-flex rounded-1 is-invalid"
      [formControl]="formControl"
      [class.is-invalid]="hasErrors"
      [attr.placeholder]="placeHolder"
      [title]="label"
      [id]="id"
      [attr.min]="minimum"
      [attr.max]="maximum"
      [attr.step]="step"
      (change)="applyValidation()"
      [readOnly]="isInputReadOnly"
      [class.isInputReadOnly]="isInputReadOnly"
    />
    <div class="input-group-append" *ngIf="format === FormatType.Percentage">
      <span class="input-group-text">%</span>
    </div>
  </div>
</div>
