<!-- Removed formControl=formControl to avoid console errors, real solution will be implemented with file upload functionality -->
<input
  class="d-none"
  *ngIf="enabled"
  type="file"
  id="fileInput"
  #fileInput
  (change)="change($event)"
/>

<label
  for="fileInput"
  [ngClass]="readOnly ? 'opacity-50 pe-none' : 'null'"
  class="card p-3 d-inline-flex align-items-center gap-3 cursor-pointer container-fluid"
  (drop)="onFileDrop($event)"
  (dragover)="onDragOver($event)"
>
  <div class="position-relative mb-3 upload-icon-box">
    <i class="bi bi-upload position-absolute top-3 upload-icon"></i>
  </div>
  <div class="d-flex flex-column gap-2">
    <div class="text-center">Click or drag file to this area to upload</div>
    <div *ngIf="fileSizeLimit; else noLimit" class="text-center text-muted">
      Support for a single or bulk upload. Maximum file size
      {{ fileSizeLimit }}MB.
    </div>
    <ng-template #noLimit>
      <div class="text-center text-muted">
        Support for a single or bulk upload. No maximum file size.
      </div>
    </ng-template>
  </div>
</label>
