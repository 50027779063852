import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { QuestionEvent } from '../steps/step-detail/step-detail.component';
import { BaseQuestion } from '../../models/Question';
import { FormGroup } from '@angular/forms';
import { RuleTriggerEvent } from '../../common/services/flows/flow.service';

@Directive()
export class QuestionBaseComponent<T> {
  @Input() question!: BaseQuestion;
  @Input() form!: FormGroup;
  @Input() readOnly!: boolean;
  @Input() flowId!: string;
  @Input() showComment!: boolean;
  @Output() valueChanged = new EventEmitter<QuestionEvent>();
  /**
   * @description Function that is triggered when a question value is changed or event is fired.
   * Does nothing if question is disabled or step is in read only mode.
   * Otherwise emits the question ID, question value and the event.
   * @returns { void }
   */
  change(value: T, files?: File[]) {
    if (!this.question.enabled || this.readOnly) {
      return;
    }
    this.valueChanged.emit(
      new QuestionEvent(
        this.question.id,
        RuleTriggerEvent.QuestionUpdated,
        value,
        files,
      ),
    );
  }
}
