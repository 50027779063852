import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  RowEvent,
  TableQuestionComponent,
} from '../table-question/table-question.component';
import { TableOperationType } from 'src/app/common/services/flows/flow.service';
import { QuestionEvent } from '../../../steps/step-detail/step-detail.component';
import { Question } from 'src/app/models/Question';
import { TableCellValue } from 'src/app/models/TableQuestion';

@Component({
  selector: 'table-grid',
  templateUrl: './table-grid.component.html',
  styleUrls: ['./table-grid.component.scss'],
})
export class TableGridComponent
  extends TableQuestionComponent
  implements OnInit
{
  @Input() isRowsFixed = false;
  @Input() isRemoveButtonHidden = false;
  @Input() isReadOnly = false;
  @Input() enableRowDuplication = false;

  @Output() rowEvent = new EventEmitter<RowEvent>();
  @Output() tableValueUpdateEvent = new EventEmitter<QuestionEvent>();

  public mappedFooterQuestions: Question<TableCellValue>[][] = [];

  override ngOnInit(): void {
    super.ngOnInit();

    // This is needed to avoid mutation of the original array
    this.mappedFooterQuestions = this.footerQuestions.map((row) => {
      return row.map((question) => {
        return { ...question, showTitle: false };
      });
    });
  }

  // Used to emit the event to the parent component
  rowRemove(index: number): void {
    this.rowEvent.emit(new RowEvent(index, TableOperationType.RemoveRow));
  }

  // Used to emit the event to the parent component
  rowDuplicate(index: number): void {
    this.rowEvent.emit(new RowEvent(index, TableOperationType.DuplicateRow));
  }

  onQuestionEvent(questionEvent: QuestionEvent): void {
    this.tableValueUpdateEvent.emit(questionEvent);
  }
}
