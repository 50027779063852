import { Component, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FFInputComponentBase } from '../../ff-base/ff-input-component-base';

@Component({
  selector: 'ff-select',
  templateUrl: './ff-select.component.html',
  host: {
    '(change)':
      'onChangeEvent(formControl.value?.[0] == null ? null : formControl.value)',
  },
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFSelectComponent extends FFInputComponentBase implements OnInit {
  @Input() options?: any[];
  @Input() defaultValue?: string;
  @Input() addNullOption: boolean = true;

  override ngOnInit(): void {
    super.ngOnInit();
    this.formControl.patchValue(this.control.value ?? this.defaultValue);
  }
}
