<div class="modal-header">
  <ff-text class="fs-5 fw-medium" [text]="title"></ff-text>
  <i class="bi bi-x-lg cursor-pointer" (click)="close()"></i>
</div>
<div class="modal-body">
  <ff-text [text]="message"></ff-text>
  <ng-container #container></ng-container>
</div>
<div class="modal-footer border-top-0">
  <ff-button
    [buttonType]="buttonTypeEnum.Secondary"
    [textValue]="cancelButtonText"
    [enabled]="true"
    [buttonSize]="buttonSizeEnum.Medium"
    [buttonTooltip]="false"
    (OnClick)="close()"
  ></ff-button>
  <ff-button
    [buttonType]="buttonTypeEnum.Primary"
    [textValue]="confirmButtonText"
    [enabled]="true"
    [buttonSize]="buttonSizeEnum.Medium"
    [buttonTooltip]="false"
    (OnClick)="confirm()"
  ></ff-button>
</div>
