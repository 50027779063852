<div [formGroup]="form">
  <div *ngIf="choice && !readOnly" [ngSwitch]="choice.choiceType">
    <ff-radio
      *ngSwitchCase="choiceTypeEnum.Radio"
      [formControlName]="choice.id"
      [tooltip]="choice.description"
      [label]="choice.title"
      [id]="choice.id"
      [required]="choice.required!"
      [enabled]="choice.enabled"
      [options]="choice.choices"
      [showTitle]="choice.showTitle"
      (OnChange)="singleChoiceChange($event)"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </ff-radio>

    <div *ngSwitchCase="choiceTypeEnum.Dropdown">
      <div *ngIf="choice.multiSelect; else singleSelect">
        <ff-multiselect
          [formControlName]="choice.id"
          [tooltip]="choice.description"
          [label]="choice.title"
          [id]="choice.id"
          [required]="choice.required!"
          [enabled]="choice.enabled"
          [options]="choice.choices"
          [showTitle]="choice.showTitle"
          (OnChange)="change($event)"
        >
          <ng-container *ngTemplateOutlet="commentIcon" />
        </ff-multiselect>
      </div>

      <ng-template #singleSelect>
        <ff-select
          [formControlName]="choice.id"
          [tooltip]="choice.description"
          [label]="choice.title"
          [id]="choice.id"
          [required]="choice.required!"
          [enabled]="choice.enabled"
          [options]="choice.choices"
          [showTitle]="choice.showTitle"
          (OnChange)="change($event)"
        >
          <ng-container *ngTemplateOutlet="commentIcon" />
        </ff-select>
      </ng-template>
    </div>

    <ff-checkbox
      *ngSwitchCase="choiceTypeEnum.Checkbox"
      [formControlName]="choice.id"
      [tooltip]="choice.description"
      [label]="choice.title"
      [id]="choice.id"
      [required]="choice.required!"
      [enabled]="choice.enabled"
      [options]="choice.choices"
      [showTitle]="choice.showTitle"
      (OnChange)="change($event)"
    >
      <ng-container *ngTemplateOutlet="commentIcon" />
    </ff-checkbox>
  </div>
  <ff-textbox
    *ngIf="choice && readOnly"
    [tooltip]="choice.description"
    [label]="choice.title"
    [id]="choice.id"
    [required]="choice.required!"
    [showTitle]="choice.showTitle"
    [value]="choiceReadOnlyValue"
    [showComment]="showComment"
    [enabled]="choice.enabled"
  >
    <ng-container *ngTemplateOutlet="commentIcon" />
  </ff-textbox>
</div>

<ng-template #commentIcon>
  <ng-content />
</ng-template>
