import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { URIService } from './uri.service';
import {
  FileExtensionPermissionType,
  FileUploadStorageOptions,
  FileUploadStorageType,
} from 'src/app/models/FileUpload';
import { InterceptorSkipHeader } from 'src/app/common/interceptors/ffx-api.interceptor';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private _domain: string = environment.ffxFlowApiUrl;
  private _sharePointFileDownloadUrl = '/File/DownloadLink/SharePoint';
  private _azureFileDownloadSasUrl = '/File/DownloadLink/Azure?fileUri=';
  private _azureDeleteFileUrl = '/File/Azure?fileUri=';

  constructor(
    private http: HttpClient,
    private _uriService: URIService,
  ) {}

  /**
   * @description Calls the download file with correct parameters for sharepoint
   * @param { type } fileName Parameter desc
   * @param { type } relativePath The files relative path
   * @param { type } isInStep Boolean to determine if the file is in step
   * @returns { void }
   */
  downloadSharepointFile(
    fileName: string,
    relativePath: string,
    isInStep: boolean = false,
  ): void {
    this.getSharePointUriLink(relativePath, isInStep).subscribe((fileUrl) => {
      this.downloadFile(fileName, fileUrl);
    });
  }

  /**
   * @description Calls the download file with correct parameters for sharepoint
   * @param { type } relativePath The files relative path
   * @param { type } isInStep Boolean to determine if the file is in step
   * @returns { void }
   */
  getSharePointUriLink(
    relativePath: string,
    isInStep?: boolean,
  ): Observable<string> {
    // Get file URL
    const appUrl = this._uriService.getParam('appUrl');
    if (appUrl) {
      let params = new HttpParams();
      params = isInStep
        ? params.set('siteUri', this.parseUri(appUrl, relativePath))
        : params.set('siteUri', appUrl);
      params = params.set('relativePath', relativePath);

      return this.http.get(
        `${this._domain}${this._sharePointFileDownloadUrl}`,
        {
          params,
          responseType: 'text',
        },
      );
    } else {
      return new Observable<string>((observer) => {
        observer.error('Missing appUrl parameter');
      });
    }
  }

  /**
   * @description Calls the download file with correct parameter for azure
   * @param { string } fileUrl Location of file
   * @param { string } fileName Name of file
   */
  downloadAzureFile(fileUrl: string, fileName: string): void {
    this.http
      .get<string>(`${this._domain}${this._azureFileDownloadSasUrl}${fileUrl}`)
      .subscribe((resultSASUri) => {
        const headers = new HttpHeaders().append(InterceptorSkipHeader, '');
        this.downloadFile(fileName, resultSASUri, headers);
      });
  }

  /**
   * @description Starts download of file for parameters given
   * @param { string } fileName Name to give file downloaded
   * @param { string } downloadFileApiUrl Endpoint to downloaded file from
   * @param { HttpHeaders | null } headers Headers to add to request
   * @returns { void }
   */
  downloadFile(
    fileName: string,
    downloadFileApiUrl: string,
    headers: HttpHeaders = new HttpHeaders(),
  ): void {
    this.getFile(downloadFileApiUrl, headers).subscribe((data: Blob) => {
      const windowTimeout = 100;
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.target = '_blank';
      link.download = fileName;
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      );
      // Some browsers require a small bit of time
      setTimeout(() => {
        window.URL.revokeObjectURL(fileURL);
        link.remove();
      }, windowTimeout);
    });
  }

  getSharePointImage(
    fileName: string,
    relativePath: string,
  ): Observable<File | null> {
    return this.getSharePointUriLink(relativePath).pipe(
      switchMap((fileUrl) =>
        this.getFile(fileUrl, new HttpHeaders()).pipe(
          map((data: Blob) => {
            // Return the File object as an observable
            return new File([data], fileName, { type: fileName });
          }),
          catchError((error) => {
            console.error('Error fetching file data:', error);
            return of(null); // Return null if there is an error
          }),
        ),
      ),
      catchError((error) => {
        console.error('Error fetching file URL:', error);
        return of(null); // Return null if there is an error
      }),
    );
  }

  /**
   * @description Deletes file from Azure storage
   * @param { string } fileUrl Location of file
   * @param { string } fileName Name of file to delete
   * @returns { Observable<boolean> } Whether the file was successfully delete
   */
  getAzureImage(fileUrl: string, fileName: string): Observable<File | null> {
    const headers = new HttpHeaders().append(InterceptorSkipHeader, '');
    return this.http
      .get<string>(`${this._domain}${this._azureFileDownloadSasUrl}${fileUrl}`)
      .pipe(
        switchMap((fileUrl) =>
          this.getFile(fileUrl, headers).pipe(
            switchMap((data: Blob) => {
              // Return the File object as an observable
              return of(new File([data], fileName, { type: fileName }));
            }),
            catchError((error) => {
              console.error('Error fetching file data:', error);
              return of(null); // Return null if there is an error
            }),
          ),
        ),
        catchError((error) => {
          console.error('Error fetching file URL:', error);
          return of(null); // Return null if there is an error
        }),
      );
  }

  /**
   * @description Gets file from the endpoint
   * @param { string } downloadFileUrl The endpoint to make call
   * @param { HttpHeaders } headers Headers to add to request
   * @returns { Observable<Blob> } Whats retrieved from the endpoint
   */
  getFile(downloadFileUrl: string, headers: HttpHeaders): Observable<Blob> {
    const options = {
      responseType: ResponseContentType.Blob,
      headers: headers,
    };

    return this.http.get(`${downloadFileUrl}`, options);
  }

  /**
   * @description Deletes file from SharePoint
   * @param { string } relativePath Relative path of file
   * @returns { Observable<boolean> } Whether the file was successfully delete
   */
  deleteSharePointFile(relativePath: string): Observable<boolean> {
    return this.getDigestValue().pipe(
      switchMap((formDigestValue: any) => {
        if (!formDigestValue) {
          // Handle the case where getDigestValue() failed
          return of(false);
        }
        return this.getSharePointUriLink(relativePath).pipe(
          switchMap((resultLink) => {
            const headers = new HttpHeaders()
              .append('X-HTTP-Method', 'DELETE')
              .append('IF-MATCH', '*')
              .append('X-RequestDigest', formDigestValue.FormDigestValue);
            // Remove the suffix string
            resultLink = resultLink.replace('/$value', '');
            return this.http.post(resultLink, null, { headers }).pipe(
              map(() => true),
              catchError((err) => {
                console.log('Error deleting file :' + err);
                return of(false);
              }),
            );
          }),
          catchError((err) => {
            console.log('Error getting SP Url :' + err);
            return of(false);
          }),
        );
      }),
      catchError((err) => {
        console.log('Error getting digest value :' + err);
        return of(false);
      }),
    );
  }

  /**
   * @description Gets the digest value used for make API calls to SharePoint
   * @returns { Observable<string | null> } SharePoint digest value
   */
  getDigestValue(): Observable<string | null> {
    const appUrl = this._uriService.getParam('appUrl');
    const headers = new HttpHeaders()
      .append('accept', 'application/json; odata=verbose')
      .append('content-type', 'application/json;odata=verbose');

    return this.http.post<any>(`${appUrl}/_api/contextinfo`, { headers }).pipe(
      catchError((error) => {
        console.error('Error getting digest value:', error);
        return of(null); // Return a default or error value
      }),
    );
  }

  /**
   * @description Deletes file from Azure storage
   * @param { string } fileUrl Location of file
   * @returns { Observable<boolean> } Whether the file was successfully delete
   */
  deleteAzureFile(fileUrl: string): Observable<boolean> {
    const url = `${this._domain}${this._azureDeleteFileUrl}${fileUrl}`;

    return this.http.delete<boolean>(url);
  }

  deleteListItemFile(
    storageOptions: FileUploadStorageOptions,
    fileUrl: string,
  ): Observable<boolean> {
    if (storageOptions.type == FileUploadStorageType.Sharepoint) {
      return this.deleteSharePointFile(fileUrl);
    } else if (storageOptions.type == FileUploadStorageType.Azure) {
      return this.deleteAzureFile(fileUrl);
    }
    return of(false);
  }

  /**
   * @description Check is valid image name
   * @param { string } fileName Name of file to check
   * @returns { boolean } Whether the file name is an allowed image
   */
  isImageFileName(fileName: string): boolean {
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    const imageExtensions: string[] = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'bmp',
      'tif',
      'tiff',
      'ico',
    ];

    // Check if the file extension is in the list of image extensions
    return !!fileExtension && imageExtensions.includes(fileExtension);
  }

  private parseUri(siteUri: string, relativePath: string): string {
    const parsedUri = new URL(siteUri);
    const authority = parsedUri.origin;
    const pathSegments = parsedUri.pathname.split('/');
    const originalRelativePathSegments = relativePath.split('/');
    let result = '';
    let i = 0;
    while (i < originalRelativePathSegments.length) {
      if (pathSegments[i] === originalRelativePathSegments[i]) {
        result += pathSegments[i] + '/';
        i++;
      } else break;
    }
    return authority + result;
  }

  /**
   * @description checks if the file extension is valid
   * @param file file to be checked
   * @param fileExtensionPermissionType permission type: allowed or dissallowed
   * @param extensions extensions to check
   * @returns { boolean } if the file extension is valid
   */
  static isValidFileExtension(
    file: File,
    fileExtensionPermissionType: FileExtensionPermissionType | undefined,
    extensions: string[] | undefined,
  ): boolean {
    if (extensions && extensions.length > 0) {
      const fileExtResult = this.allowedOrDisallowedFileExt(
        file.name,
        fileExtensionPermissionType,
        extensions,
      );
      if (fileExtResult) return true;
      return false;
    }
    return true;
  }

  static allowedOrDisallowedFileExt(
    fileName: string,
    fileExtensionPermissionType: FileExtensionPermissionType | undefined,
    extensions: string[],
  ): boolean | undefined {
    switch (fileExtensionPermissionType) {
      case FileExtensionPermissionType.Allowed:
        return extensions?.includes(
          `.${fileName.split('.').pop()?.toLowerCase()}`,
        );
      case FileExtensionPermissionType.Disallowed:
        return !extensions?.includes(
          `.${fileName.split('.').pop()?.toLowerCase()}`,
        );
    }
    return true;
  }

  /**
   * @description checks if the file size is valid
   * @param file file to be checked
   * @param fileSizeLimit file size limit
   * @returns { boolean } if the file size is valid
   */
  static isValidFileSize(
    file: File,
    fileSizeLimit: number | undefined,
  ): boolean {
    if (fileSizeLimit) {
      return file.size / (1024 * 1024) <= fileSizeLimit;
    }
    return true;
  }
}

export enum ResponseContentType {
  Blob = 'blob',
}
