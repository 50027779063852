<div class="invalid-feedback mb-1">
  <span *ngIf="errors?.['required']" i18n>
    Please provide a valid {{ label }}
  </span>
  <span *ngIf="errors?.['minlength']">
    Min {{ errors?.["minlength"]["requiredLength"] }}
  </span>
  <span *ngIf="errors?.['customError']">
    {{ errors?.["customError"] }}
  </span>
  <span *ngIf="errors?.['invalidFile']">
    Invalid file: {{ errors?.["invalidFile"] }}
  </span>
  <!-- <p *ngIf="ffControl.control.errors">{{ffControl.control.errors | json }}</p> -->
</div>
