<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>
  <ff-control-validation
    *ngIf="hasErrors"
    [errors]="control.errors"
    [label]="label"
  />

  <input
    type="text"
    class="form-control d-flex rounded-1 is-invalid"
    [formControl]="formControl"
    [class.is-invalid]="hasErrors"
    [attr.placeholder]="placeHolder"
    [title]="label"
    [id]="id"
    [attr.maxlength]="maxLength"
    [readOnly]="isInputReadOnly"
    [class.isInputReadOnly]="isInputReadOnly"
  />
</div>

<!-- <strong>Valid ? </strong>{{control.valid}} <br>
<strong>Invalid ? </strong>{{control.invalid}}<br>
<strong>Touched ? </strong>{{control.touched}}<br>
<strong>Untouched ? </strong>{{control.untouched}}<br>
<strong>Dirty ? </strong>{{control.dirty}}<br>
<strong>Pristine ? </strong>{{control.pristine}}<br> -->
