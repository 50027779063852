<div [formGroup]="form">
  <ff-input-text
    *ngIf="slt && !readOnly"
    [formControlName]="slt.id"
    [tooltip]="slt.description"
    [label]="slt.title"
    [id]="slt.id"
    [required]="slt.required!"
    [showTitle]="slt.showTitle"
    [maxLength]="slt.maxLength"
    [enabled]="slt.enabled"
    [isInputReadOnly]="isCalculatedQuestion"
    (OnChange)="change($event)"
  >
    <ng-container *ngTemplateOutlet="commentIcon"></ng-container>
  </ff-input-text>
  <ff-textbox
    *ngIf="slt && readOnly"
    [tooltip]="slt.description"
    [label]="slt.title"
    [id]="slt.id"
    [required]="slt.required!"
    [showTitle]="slt.showTitle"
    [value]="slt.value"
    [showComment]="showComment"
    [enabled]="slt.enabled"
  >
    <ng-container *ngTemplateOutlet="commentIcon"></ng-container>
  </ff-textbox>
</div>

<ng-template #commentIcon>
  <ng-content></ng-content>
</ng-template>
