import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertType } from '../../../visual-components/components/ff-message-divider/ff-message-divider.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Feedback, FeedbackType } from '../../../models/Feedback';
import { Attachment } from '../../../models/Attachment';
import { FileService } from '../../../common/services/file.service';
import { FileUploadStorageType } from '../../../models/FileUpload';

@Component({
  selector: 'step-submitted',
  templateUrl: './step-submitted.component.html',
  styleUrls: ['./step-submitted.component.scss'],
})
export class StepSubmissionComponent {
  @Input() flowTitle?: string;
  @Input() formTitle?: string;
  @Input() stepTitle?: string;
  @Input() message?: string;
  @Input() stepIndex?: number;
  @Input() lastStep: boolean = false;
  @Input() attachments?: Attachment[];
  @Input() logo!: string;
  stepFeedback: Feedback = new Feedback();
  selectedFeedbackType?: FeedbackType;
  maxCharacterLimit: number = 300;
  characterCount: number = 0;

  public form = new FormGroup({
    feedback: new FormControl('', [
      Validators.maxLength(this.maxCharacterLimit),
    ]),
  });

  @Output()
  stepFeedbackAction = new EventEmitter<Feedback>();

  @Output()
  stepSubmissionAction = new EventEmitter<StepSubmittedAction>();

  stepSubmittedActionEnum: typeof StepSubmittedAction = StepSubmittedAction;
  alertTypeEnum: typeof AlertType = AlertType;
  feedbackTypeEnum: typeof FeedbackType = FeedbackType;
  fileUploadStorageTypeEnum: typeof FileUploadStorageType =
    FileUploadStorageType;

  constructor(private _fileService: FileService) {}

  /**
   * @description Handles the action from button click on the submitted step
   * @param { StepSubmittedAction } action Action to be taken
   * @returns { void }
   */
  clickAction(action: StepSubmittedAction): void {
    this.stepSubmissionAction.emit(action);
  }

  /**
   * @description Gets the step index of the submitted step.
   * @returns { void }
   */
  get stepSubTitle(): string {
    let currentStepIndex = this.stepIndex!;
    return `Step ${++currentStepIndex}`;
  }

  onFeedbackClick(feedbackType: FeedbackType): void {
    this.resetFeedbackCommentIfTouched();

    this.stepFeedback.type =
      feedbackType === this.stepFeedback.type ? undefined : feedbackType;

    if (this.stepFeedback.type !== undefined) {
      this.stepFeedbackAction.emit(this.stepFeedback);
    }
  }

  changeFeedback(feedback: string): void {
    this.form.controls.feedback.setValue(feedback);

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.stepFeedback.type === FeedbackType.Negative) {
      this.stepFeedback.comment = this.form.value.feedback as string;
      this.stepFeedbackAction.emit(this.stepFeedback);
    }
  }

  attachmentLinkClick(attachment: Attachment): void {
    switch (attachment.storageType) {
      case this.fileUploadStorageTypeEnum.Sharepoint:
        this._fileService.downloadSharepointFile(
          attachment.displayName!,
          attachment.fileUrl!,
          true,
        );
        break;
      case this.fileUploadStorageTypeEnum.Azure:
        this._fileService.downloadAzureFile(
          attachment.fileUrl!,
          attachment.displayName!,
        );
        break;
      default:
        break;
    }
  }

  keyUp(value: string): void {
    if (value) this.characterCount = value.length;
  }

  private resetFeedbackCommentIfTouched(): void {
    if (this.form.controls.feedback.touched) {
      this.form.controls.feedback.reset();
      this.stepFeedback.comment = undefined;
      this.characterCount = 0;
    }
  }
}

export enum StepSubmittedAction {
  Proceed = 'proceed',
  View = 'view',
  Exit = 'exit',
}
