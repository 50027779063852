<div class="form-navigation">
  <button
    class="btn btn-primary"
    type="button"
    (click)="navigateToPreviousInvalid()"
    [disabled]="!hasPreviousInvalid()"
  >
    Previous
  </button>
  <button
    class="btn btn-primary"
    type="button"
    (click)="navigateToNextInvalid()"
    [disabled]="!hasNextInvalid()"
  >
    Next
  </button>
</div>
