import { Component } from '@angular/core';
import { NgControl } from '@angular/forms';

import { FFDateAndTimeComponentBase } from '../ff-base/ff-dateAndTime-component-base';

@Component({
  selector: 'ff-time-only',
  templateUrl: './ff-time-only.component.html',
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFTimeOnlyComponent extends FFDateAndTimeComponentBase {
  override formatDate(value: string | null): string | null {
    if (!value) {
      return null;
    }

    let formattedDate = null;

    if (this.isValidDateTime(value)) {
      formattedDate = this.datePipe.transform(value, 'HH:mm');
    } else {
      const defaultDate = new Date();
      const dateAndTime = defaultDate.toDateString() + ' ' + value;

      // Check if after adding the default date the value is a valid date
      if (!this.isValidDateTime(dateAndTime))
        throw new Error('Invalid time: ' + value);

      formattedDate = this.datePipe.transform(dateAndTime, 'HH:mm');
    }

    return formattedDate == '' ? null : formattedDate;
  }

  override toIsoString(value: string | null): string | null {
    if (!value) {
      return null;
    }

    let formattedDate = null;

    if (this.isValidDateTime(value)) {
      formattedDate = value;
    } else {
      const defaultDate = new Date();
      const dateAndTime = defaultDate.toDateString() + ' ' + value;

      // Check if after adding the default date the value is a valid date
      if (!this.isValidDateTime(dateAndTime))
        throw new Error('Invalid time: ' + value);

      formattedDate = dateAndTime;
    }

    return super.toIsoString(formattedDate);
  }
}
