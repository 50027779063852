import { Component, Input, OnInit } from '@angular/core';
import { FFInputComponentBase } from '../ff-base/ff-input-component-base';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'ff-radio',
  templateUrl: './ff-radio.component.html',
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFRadioComponent extends FFInputComponentBase implements OnInit {
  @Input() options?: any[];
  @Input() defaultValue?: string;
  @Input() horizontalLayout?: boolean = false;

  override ngOnInit(): void {
    super.ngOnInit();
    this.formControl.patchValue(this.control.value ?? this.defaultValue);
  }

  override onChangeEvent(): void {
    this.onChanged(this.formControl.value);

    if (this.control.valid) {
      this.OnChange.emit(this.formControl.value);
    }
  }
}
