import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Attachment } from '../../../models/Attachment';
import { FileService } from '../../../common/services/file.service';
import { FileUploadStorageType } from '../../../models/FileUpload';

@Component({
  selector: 'step-attachments',
  templateUrl: './step-attachments.component.html',
})
export class StepAttachmentsComponent {
  @Input() attachments!: Attachment[];
  @Input() isStepCompleted!: boolean;

  @Output() stepAttachmentDelete: EventEmitter<string> =
    new EventEmitter<string>();

  fileUploadStorageTypeEnum: typeof FileUploadStorageType =
    FileUploadStorageType;

  constructor(private _fileService: FileService) {}

  fileDownload(attachment: Attachment) {
    switch (attachment.storageType) {
      case this.fileUploadStorageTypeEnum.Sharepoint:
        this._fileService.downloadSharepointFile(
          attachment.displayName!,
          attachment.fileUrl!,
          true,
        );
        break;
      case this.fileUploadStorageTypeEnum.Azure:
        this._fileService.downloadAzureFile(
          attachment.fileUrl!,
          attachment.displayName!,
        );
        break;
      default:
        break;
    }
  }

  fileDelete(attachment: Attachment) {
    this._fileService
      .deleteListItemFile(
        { type: attachment.storageType, overwrite: undefined },
        attachment.fileUrl!,
      )
      .subscribe((result) => {
        if (result) {
          this.stepAttachmentDelete.emit(attachment.id);
        }
      });
  }

  get attachmentTitle(): string {
    return this.isStepCompleted ? 'Attachments' : 'Add attachments (optional)';
  }
}
