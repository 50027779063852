<div class="list-item d-flex align-items-center border rounded w-100 mb-2 p-2">
  <div class="flex-grow-1 fs-6">
    <div class="d-flex align-items-center gap-2">
      <div
        class="d-flex justify-content-center align-items-center"
        *ngIf="image"
      >
        <img class="rounded img-preview" [alt]="fileName" [src]="imageSrc" />
      </div>
      <button
        class="btn btn-link text-start text-primary p-0 m-0 flex-grow-1 btn-text"
        (click)="handleClick()"
      >
        {{ fileName }}
      </button>
    </div>
  </div>
  <div class="d-inline-flex align-items-center">
    <div class="d-flex justify-content-center align-items-center">
      <button
        *ngIf="!readOnly"
        class="btn btn-link text-start p-0 m-0 btn-delete btn-text"
        (click)="handleDeleteClick()"
      >
        <span class="bi bi-trash"></span>
        <span class="visually-hidden">Delete</span>
      </button>
    </div>
  </div>
</div>
