import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './components/app.component/app.component';
import { VcShowcaseComponent } from './vc-showcase/vc-showcase.component';
import { FormComponent } from './components/forms/form/form.component';
import { HomeComponent } from './components/home/home.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { LogoutGuard } from '@flowforma/core';

const routes: Routes = [
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent,
  },
  { path: '', component: HomeComponent, canActivate: [MsalGuard] },
  { path: 'logout', children: [], canActivate: [LogoutGuard] },
  { path: 'oldhome', component: AppComponent },
  { path: 'showcase', component: VcShowcaseComponent },
  {
    path: 'form',
    component: FormComponent,
    canActivate: [MsalGuard],
    // children: [
    //   {
    //     path: ':id',
    //     component: StepDetailComponent
    //   }
    // ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
