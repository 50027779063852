import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'ff-upload-list-image-item',
  templateUrl: './ff-upload-list-image-item.component.html',
  styleUrls: ['./ff-upload-list-image-item.component.scss'],
})
export class FFUploadListImageItemComponent implements OnChanges {
  @Input() fileName?: string;
  @Input() image?: File | string;
  @Input() readOnly = false;

  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();

  private fileReader: FileReader;

  imageSrc: any = '';

  constructor() {
    this.fileReader = new FileReader();
    this.fileReader.onloadend = () => {
      this.imageSrc = this.fileReader.result;
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['image'] && this.image instanceof File) {
      this.fileReader.readAsDataURL(this.image);
    }
  }

  handleClick() {
    this.onClick.emit();
  }

  handleDeleteClick() {
    this.onDelete.emit();
  }
}
