import { Component, EventEmitter, Output } from '@angular/core';
import {
  ButtonSize,
  ButtonType,
} from '../../../visual-components/components/ff-button/ff-button.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'add-note',
  templateUrl: './add-note.component.html',
})
export class AddNoteComponent {
  public form = new FormGroup({
    notesControl: new FormControl(''),
  });

  buttonTypeEnum: typeof ButtonType = ButtonType;
  buttonSizeEnum: typeof ButtonSize = ButtonSize;

  @Output()
  addNoteAction = new EventEmitter<NoteAction>();

  notesChange(notes: string): void {
    this.form.controls.notesControl.setValue(notes);
  }

  Cancel(): void {
    const noteAction: NoteAction = {
      noteButtonClicked: NoteButtonClicked.Cancel,
    };
    this.addNoteAction.emit(noteAction);
  }

  Save(): void {
    this.form.controls.notesControl.setValidators([Validators.required]);
    this.form.controls.notesControl.updateValueAndValidity();

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const noteAction: NoteAction = {
      note: this.form.controls.notesControl.value as string,
      createdDate: new Date(),
      noteButtonClicked: NoteButtonClicked.Save,
    };

    this.addNoteAction.emit(noteAction);
  }
}

export interface NoteAction {
  note?: string;
  createdDate?: Date;
  noteButtonClicked: NoteButtonClicked;
}

export enum NoteButtonClicked {
  Cancel,
  Save,
}
