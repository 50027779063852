import { Pipe, PipeTransform } from '@angular/core';
import { getLocaleCurrencySymbol } from '@angular/common';

@Pipe({
  name: 'currencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(locale: string): string | null {
    return getLocaleCurrencySymbol(locale);
  }
}
