import { Component, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { FFInputComponentBase } from '../../ff-base/ff-input-component-base';

@Component({
  selector: 'ff-input-text',
  templateUrl: './ff-input-text.component.html',
  styleUrls: ['./ff-input-text.component.scss'],
  host: {
    '(change)': 'onChangeEvent($event.target.value)',
  },
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFInputTextComponent extends FFInputComponentBase {
  @Input() maxLength?: string;
  @Input() isInputReadOnly?: boolean;
}

@Component({
  selector: 'choose-quantity',
  template: `
    <div class="choose-quantity">
      <span (click)="onRemove()" color="primary">minus_box</span>
      <div class="quantity">{{ quantity }}</div>
      <span (click)="onAdd()" color="primary">add_box</span>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ChooseQuantityComponent,
    },
  ],
  //styleUrls: ["choose-quantity.component.scss"]
})
export class ChooseQuantityComponent implements ControlValueAccessor {
  writeValue<T>(obj: T): void {
    throw new Error('Method not implemented.');
  }
  registerOnChange<T>(fn: T): void {
    throw new Error('Method not implemented.');
  }
  registerOnTouched<T>(fn: T): void {
    throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  quantity = 0;

  @Input()
  increment: number = 1;

  onAdd() {
    this.quantity += this.increment;
  }

  onRemove() {
    this.quantity -= this.increment;
  }
}
