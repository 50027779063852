<div [formGroup]="form" *ngIf="form && multiLineText">
  <div [ngSwitch]="multiLineText.textType">
    <ff-textarea
      *ngSwitchCase="textTypeEnum.PlainText"
      [formControlName]="multiLineText.id"
      [tooltip]="multiLineText.description"
      [label]="multiLineText.title"
      [showTitle]="multiLineText.showTitle"
      [id]="multiLineText.id"
      [required]="multiLineText.required!"
      [enabled]="multiLineText.enabled"
      [numberOfLines]="multiLineText.numberOfLines"
      [numberOfColumns]="multiLineText.numberOfColumns"
      [autoSize]="multiLineText.autoSize"
      [readOnly]="readOnly"
      (OnChange)="change($event)"
      [enabled]="multiLineText.enabled"
    >
      <ng-content />
    </ff-textarea>

    <div *ngSwitchDefault></div>
  </div>
</div>
