import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import { IStep } from '../../../models/IStep';
import { Section } from '../../../models/Section';
import { BaseQuestion } from '../../../models/Question';
import { QuestionType } from '../../../models/QuestionType';
import { ViewportScroller } from '@angular/common';
import { FFModalService } from '../../../visual-components/services/ff-modal.service';
import { StepAttachmentEvent } from '../step-detail/step-detail.component';
import { StepAttachmentAction } from '../../../models/Attachment';

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnChanges, OnDestroy {
  questionType: typeof QuestionType = QuestionType;

  @Input() steps?: IStep[];
  @Input() flowTitle?: string;
  @Input() stepIndex?: number;
  @Input() selectedStep?: IStep;
  @Input() flowId!: string;
  @Input() form!: FormGroup;
  @Input() isSideStepperExpanded!: boolean;
  @Input() flowCurrentStepId?: string;
  @Input() logo!: string;
  @Output() selectedStepUpdated: EventEmitter<IStep> =
    new EventEmitter<IStep>();
  @Output() stepAttachmentUploadEvent = new EventEmitter<StepAttachmentEvent>();
  @Output() sideStepperEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  invalidQuestionIds: string[] = [];
  formValueChangesSubscription?: Subscription;

  constructor(
    private scroller: ViewportScroller,
    private _modalService: FFModalService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('selectedStep') &&
      this.selectedStep &&
      this.form
    ) {
      this.formValueChangesSubscription = this.form.valueChanges.subscribe(
        () => {
          if (this.form.invalid) {
            this.invalidQuestionIds = [];
            const formControls = this.form.controls;
            Object.keys(formControls).forEach((key) => {
              if (
                formControls[key].invalid &&
                (formControls[key].touched || formControls[key].dirty)
              ) {
                this.invalidQuestionIds.push(key);
              }
            });
          } else {
            this.invalidQuestionIds = [];
          }
        },
      );
    }
  }

  ngOnDestroy(): void {
    if (this.formValueChangesSubscription)
      this.formValueChangesSubscription?.unsubscribe();
  }

  getSectionQuestion(sectionId: string): BaseQuestion[] | undefined {
    const section = this.selectedStep?.questions?.find(
      (s) => s.id === sectionId,
    ) as Section;
    return section?.questions;
  }

  /**
   * @description Emits the currently selected though the output
   * @param { IStep } step Step selected
   * @returns { void }
   */
  onSelectedStepChange(step: IStep): void {
    if (this.selectedStep?.id === step.id || step.isFutureStep) {
      return;
    }

    this.invalidQuestionIds = [];
    this.closeStepper();
    this.selectedStepUpdated.emit(step);
  }

  /**
   * @description Performs action for menu item click
   * Action parameter not or implemented yet
   * @param { IStep } step Current step when context menu item was clicked
   * @returns { void }
   */
  menuItemClick(step: IStep): void {
    console.log(step);
  }

  /**
   * @description Performs action scroll to question
   * Action parameter not or implemented yet
   * @param { string } id Selected question id
   * @returns { void }
   */
  scrollToElementById(id: string): void {
    this.closeStepper();
    this.scroller.scrollToAnchor('ScrollTo' + id);
  }

  /**
   * @description Opens a modal for delegating a step
   * @param step the step to delegate
   * @returns { void }
   */
  public delegateStep(step: IStep): void {
    this._modalService.openDelegateDialog(
      this.flowId,
      step,
      this.flowTitle ?? '',
      this.stepIndex ?? 0,
      this.logo,
    );
  }

  public addAttachments(
    stepId: string,
    unallowedAttachmentFileTypes: string[],
  ): void {
    this._modalService.openAddAttachmentsDialog(
      stepId,
      unallowedAttachmentFileTypes,
      (files: File[]) => {
        if (files) {
          this.stepAttachmentUploadEvent.emit(
            new StepAttachmentEvent(
              StepAttachmentAction.Upload,
              stepId,
              undefined,
              files,
            ),
          );
        }
      },
    );
  }

  /**
   * @description couts the number of issues to review
   * @returns { number } a number of issues to review
   */
  public countIssuesToReview(): number {
    if (!this.selectedStep?.questions) {
      return 0;
    }

    const countUnaddressed = (questions: BaseQuestion[]): number => {
      return questions.reduce((total, question) => {
        if (question.commentThread?.isAddressed === false) {
          total++;
        }
        if (question.questionType === QuestionType.Section) {
          total += countUnaddressed(
            (question as any).questions as BaseQuestion[],
          );
        }
        return total;
      }, 0);
    };

    return countUnaddressed(this.selectedStep.questions);
  }

  /**
   * @description checks if question is invalid
   * @param question the question to check
   * @returns  { boolean } whether the question is invalid
   */
  public isInvalidQuestion(question: BaseQuestion): boolean {
    return this.invalidQuestionIds.includes(question.id);
  }

  /**
   * @description gets the question class based on the question issue
   * @param question question
   * @returns { string } class name
   */
  public getQuestionWithIssuesClass(question: BaseQuestion): string {
    if (question.questionType === QuestionType.Section) {
      return '';
    } else if (this.isInvalidQuestion(question)) {
      return 'text-danger';
    } else if (question.commentThread) {
      return 'text-warning';
    } else {
      return '';
    }
  }

  closeStepper(): void {
    this.sideStepperEvent.emit(false);
  }

  showStepActive(step: IStep): boolean {
    if (step?.id === this.selectedStep?.id) return true;
    if (step.submittedDate) return true;
    if (step?.id === this.flowCurrentStepId) return true;
    return false;
  }
}
