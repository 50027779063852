import { Component, OnInit } from '@angular/core';
import { FFInputMultipleValuesComponentBase } from '../ff-base/ff-input-multiple-values-base';

@Component({
  selector: 'ff-multiselect',
  templateUrl: './ff-multiselect.component.html',
  styleUrls: ['./ff-multiselect.component.scss'],
})
export class FFMultiselectComponent
  extends FFInputMultipleValuesComponentBase
  implements OnInit
{
  override ngOnInit() {
    super.ngOnInit();
    this.formControl.patchValue(this.control.value ?? this.defaultValues);
  }

  /**
   * @description Update the values of the control and emit the change event
   */
  updateValues() {
    this.onChanged(this.formControl.value);

    if (this.control.valid) {
      this.OnChange.emit(this.formControl.value);
    }
  }

  /**
   * @description Override the onChangeEvent to update values only if the event is not from the panel
   * @param event
   */
  override onChangeEvent(event: MultiSelectEvent): void {
    // onPanelHide and actions in panel have originalEvent so we skip them here
    // because we want those events to only update when panel is closed
    if (!event.originalEvent) {
      this.updateValues();
    }
  }

  /**
   * @description Update the values when the panel is closed
   */
  onHide() {
    this.updateValues();
  }
}

export interface MultiSelectEvent {
  itemValue: string;
  originalEvent: PointerEvent;
  value: string[];
}
