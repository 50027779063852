<div class="{{ alertType }} alert" [ngSwitch]="alertType">
  <span *ngIf="icon; else useDefaultIcon">
    <i [class]="icon"></i>
  </span>
  <ng-template #useDefaultIcon>
    <span *ngSwitchCase="alertTypeEnum.Information"
      ><i class="bi bi-info-circle"></i
    ></span>
    <span *ngSwitchCase="alertTypeEnum.Alert"
      ><i class="bi bi-exclamation-circle"></i
    ></span>
    <span *ngSwitchCase="alertTypeEnum.Success"
      ><i class="bi bi-check-circle"></i
    ></span>
    <span *ngSwitchCase="alertTypeEnum.StepSubmittedHistory"
      ><i class="bi bi-check-circle-fill"></i
    ></span>
    <span *ngSwitchCase="alertTypeEnum.Warning"
      ><i class="bi bi-exclamation-circle-fill"></i
    ></span>
  </ng-template>
  <span class="ms-2 fw-bold">{{ title }}</span>
  <div *ngIf="subTitle" class="fs-8 mt-2">{{ subTitle }}</div>
  <ng-content select=".above-message"></ng-content>
  <div *ngIf="message" class="fs-7">{{ message }}</div>
  <ng-content select=".below-message"></ng-content>
</div>
