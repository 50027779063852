import { Component, OnInit } from '@angular/core';
import { DateAndTime, DateDisplayFormat } from '../../../models/DateAndTime';
import { QuestionBaseComponent } from '../question-base-component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'date-and-time',
  templateUrl: './date-and-time.component.html',
})
export class DateAndTimeComponent
  extends QuestionBaseComponent<Date>
  implements OnInit
{
  constructor(public datepipe: DatePipe) {
    super();
  }

  dateAndTime!: DateAndTime;

  formattedDateTime: string | null = null;
  displayFormat = DateDisplayFormat;

  ngOnInit(): void {
    this.dateAndTime = this.question as DateAndTime;
    const selectedDateTime = new Date(this.dateAndTime?.value ?? '');

    // convert the date time to the correct format
    if (!isNaN(selectedDateTime.valueOf())) {
      switch (this.dateAndTime.dateDisplayFormat) {
        case DateDisplayFormat.DateAndTime:
          this.formattedDateTime = this.datepipe.transform(
            selectedDateTime,
            'yyyy-MM-dd HH:mm',
          );
          break;
        case DateDisplayFormat.DateOnly:
          this.formattedDateTime = this.datepipe.transform(
            selectedDateTime,
            'yyyy-MM-dd',
          );
          break;
        case DateDisplayFormat.TimeOnly:
          this.formattedDateTime = this.datepipe.transform(
            selectedDateTime,
            'HH:mm',
          );
          break;
      }
    }
  }
}
