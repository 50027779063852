import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FeedbackPanel } from './feedback-panel.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'feedback-panel',
  templateUrl: './feedback-panel.component.html',
})
export class FeedbackPanelComponent {
  @Input() data?: FeedbackPanel;
  isEditDueDateEnabled: boolean = false;

  @Output()
  changeDueDateEvent = new EventEmitter<Date>();

  public form = new FormGroup({
    dateSelect: new FormControl('', Validators.required),
  });

  editDueDate(): void {
    this.isEditDueDateEnabled = true;
    this.controlsReset();
  }

  dueDateChange(event: string): void {
    if (!this.isEditDueDateEnabled) return;

    this.form.controls.dateSelect.setValue(event);

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const convertedDate = new Date(event);
    this.changeDueDateEvent.emit(convertedDate);

    this.isEditDueDateEnabled = false;
  }

  cancelEdit(): void {
    this.isEditDueDateEnabled = false;
  }

  private controlsReset(): void {
    this.form.controls.dateSelect.reset();
    this.form.controls.dateSelect.updateValueAndValidity();
  }
}
