<div class="container-fluid">
  <div class="row position-relative" *ngIf="flow$ | async as flow">
    <div
      *ngIf="isSideStepperExpanded"
      class="position-absolute z-2 h-100 w-100 overlay d-block d-lg-none"
      (click)="collapseStepper()"
    ></div>
    <div
      class="d-flex flex-column col-lg-3 z-3 bg-white position-fixed border border-bottom-0 border-start-0 text-dark-emphasis left-panel pe-0 stepper-max-width-58"
      *ngIf="flow$"
      [ngClass]="{
        'col-10 max-width-320': isSideStepperExpanded,
        'col-2': !isSideStepperExpanded
      }"
    >
      <div
        class="mb-3 me-0 py-lg-3 border-bottom row"
        [ngClass]="{
          'pt-3 pb-3': !isSideStepperExpanded,
          'pt-1 pb-1': isSideStepperExpanded
        }"
      >
        <div
          class="d-flex gap-2 d-lg-block"
          [ngClass]="{
            'justify-content-center align-items-center': !isSideStepperExpanded
          }"
        >
          <i
            class="bi d-inline-flex d-lg-none align-self-center"
            (click)="toggleStepper()"
            [ngClass]="{
              'bi-arrow-bar-left': isSideStepperExpanded,
              'bi-arrow-bar-right': !isSideStepperExpanded
            }"
          ></i>
          <div class="col-4">
            <img
              [src]="getLogo"
              class="img-fluid d-lg-inline-flex"
              [ngClass]="{
                'd-none': !isSideStepperExpanded,
                'd-inline': isSideStepperExpanded
              }"
              alt="Logo"
            />
          </div>
        </div>
        <div class="col-8">
          <ff-spinner></ff-spinner>
        </div>
      </div>

      <form-name
        class="d-lg-block mb-4 me-0"
        [flowTitle]="flow.title"
        [formTitle]="flow.formTitle"
        [ngClass]="{
          'd-none': !isSideStepperExpanded,
          'd-block': isSideStepperExpanded
        }"
      ></form-name>

      <div
        class="ms-0 ms-lg-2 mb-3 summary-circle-size"
        *ngIf="flow.isFlowCompleted"
      >
        <div
          class="summary-circle summary-circle-size ms-1 position-absolute rounded-circle"
          [class.selected]="canShowSummaryActive"
          (click)="onSummaryClick()"
        ></div>
        <div>
          <span
            class="summary-title fw-bold cursor-pointer d-lg-inline"
            [ngClass]="{
              'd-none': !isSideStepperExpanded,
              'd-inline': isSideStepperExpanded
            }"
            (click)="onSummaryClick()"
            >Summary</span
          >
        </div>
      </div>
      <stepper
        *ngIf="canShowStepper"
        class="h-600 d-block px-0 px-lg-2 pt-2 me-0"
        [form]="form"
        [steps]="flow.steps"
        [selectedStep]="selectedStep"
        [flowTitle]="flow.title"
        [flowId]="formGuid!"
        [stepIndex]="stepIndex"
        [isSideStepperExpanded]="isSideStepperExpanded"
        [flowCurrentStepId]="flow.currentStepId"
        [logo]="getLogo"
        (selectedStepUpdated)="onSelectedStepChange($event)"
        (stepAttachmentUploadEvent)="onStepAttachmentEvent($event)"
        (sideStepperEvent)="onSideStepperEvent($event)"
      >
      </stepper>

      <div
        class="border-top row justify-content-between align-items-center py-2 me-0 d-none d-lg-flex mt-auto"
        [ngClass]="{
          'd-none': !isSideStepperExpanded,
          'd-flex': isSideStepperExpanded
        }"
      >
        <ff-button
          class="col"
          [buttonType]="buttonTypeEnum.Secondary"
          [enabled]="true"
          [buttonSize]="buttonSizeEnum.Small"
          [buttonTooltip]="false"
          [id]="'FormSaveAndExitButton'"
          [textValue]="exitBtnText"
          (OnClick)="saveAndExit()"
          ><i class="bi bi-save2" Icon></i
        ></ff-button>

        <div class="col-6">
          <img
            [src]="poweredByLogo"
            class="img-fluid w-50"
            alt="flowforma logo"
          />
        </div>
      </div>
    </div>

    <div
      class="col-12 col-lg-9 form-detail pe-0 ps-0 form-p-left-58 ms-auto border-top"
    >
      <div class="mx-lg-8 my-lg-5">
        <div *ngIf="showQAButtons">
          <button
            class="btn btn-primary btn-lg"
            (click)="onFormRuleEvent(ruleTriggerEvent.FormCompleted)"
          >
            FormCompleted
          </button>
          <button
            class="btn btn-primary btn-lg"
            (click)="onFormRuleEvent(ruleTriggerEvent.FormStarted)"
          >
            FormStarted
          </button>
          <button
            class="btn btn-primary btn-lg"
            (click)="onFormRuleEvent(ruleTriggerEvent.FormLoaded)"
          >
            FormLoaded
          </button>
          <button
            class="btn btn-primary btn-lg"
            (click)="onFormRuleEvent(ruleTriggerEvent.FormRejected)"
          >
            FormRejected
          </button>
        </div>

        <feedback-panel
          *ngIf="reopenMode"
          [data]="feedbackPanelData"
          (changeDueDateEvent)="changeDueDate($event)"
        ></feedback-panel>

        <step-detail
          *ngIf="currentShowComponentEnum === availableComponentEnum.StepDetail"
          [form]="form"
          [step]="selectedStep!"
          [flowCompleted]="flow.isFlowCompleted"
          [stepNumber]="flow.steps.indexOf(selectedStep!) + 1"
          (stepEvent)="onStepEvent($event)"
          (questionEvent)="onQuestionEvent($event)"
          (tableEvent)="onTableEvent($event)"
          (stepNoteEvent)="onStepNoteEvent($event)"
          (continueEvent)="goNextStep()"
          (addFeedbackEvent)="addFeedback($event)"
          (reopenStepEvent)="confirmReopen($event)"
          (stepAttachmentDelete)="onStepAttachmentEvent($event)"
          (formCancelEvent)="onFormCancelEvent($event)"
          [reopenMode]="reopenMode"
          [flowId]="formGuid!"
          [lastStep]="lastStep"
          [showFormCancel]="flow.showCancel"
          [currentTime]="currentTime"
          [logo]="getLogo"
        ></step-detail>

        <step-submitted
          *ngIf="
            currentShowComponentEnum === availableComponentEnum.StepSubmitted
          "
          [flowTitle]="flow.title"
          [formTitle]="flow.formTitle"
          [message]="selectedStep!.title"
          [stepIndex]="stepIndex"
          [lastStep]="lastStep"
          [attachments]="selectedStep!.attachments"
          (stepSubmissionAction)="stepSubmissionAction($event)"
          (stepFeedbackAction)="stepFeedbackAction($event)"
          [logo]="getLogo"
        ></step-submitted>

        <form-summary
          *ngIf="
            currentShowComponentEnum === availableComponentEnum.FormSummary
          "
          [flow]="flow"
          (changeComponentView)="changeComponentView($event)"
          (getWorkflowHistoryEvent)="getWorkflowHistoryEventList()"
          (confirmReopenForm)="confirmFormReopen($event)"
          [logo]="getLogo"
        ></form-summary>

        <workflow-history
          *ngIf="
            currentShowComponentEnum === availableComponentEnum.WorkflowHistory
          "
          [flowTitle]="flow.title"
          [flowFormTitle]="flow.formTitle"
          [flowEvents]="flowEvents"
          [allStepsTitles]="allStepsTitles"
          (changeComponentView)="changeComponentView($event)"
        ></workflow-history>
      </div>
    </div>
  </div>
</div>
