<div [formGroup]="form">
  <ff-separator
    *ngIf="separator"
    [id]="separator.id"
    [tooltip]="separator.description"
    [backgroundColor]="
      !separator.useDefaultColor
        ? separator.backgroundColor
        : 'linear-gradient(90deg, #30318D 0%, #26A9E1 100%)'
    "
  >
  </ff-separator>
</div>
