import { Component, OnInit } from '@angular/core';
import {
  FileUpload,
  FileUploadAzureStorageOptions,
  FileUploadDetails,
} from '../../../models/FileUpload';
import { QuestionBaseComponent } from '../question-base-component';
import { AbstractControl } from '@angular/forms';
import { FileService } from '../../../common/services/file.service';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent
  extends QuestionBaseComponent<FileUploadDetails | null>
  implements OnInit
{
  fileArray: File[] = [];
  fileListItemUploads: FileUploadDetails[] = [];
  fileUpload!: FileUpload;

  ngOnInit(): void {
    this.fileUpload = this.question as FileUpload;
    this.updateSavedFile(this.fileUpload.value);
    this.form
      .get(this.fileUpload.id)
      ?.addValidators(this.fileExtensionValidator());
    // We need the uploaded file Id for downloading the file
    this.form.get(this.fileUpload.id)?.valueChanges.subscribe((value) => {
      this.updateSavedFile(value);
    });
  }

  updateSavedFile(file: FileUploadDetails | undefined): void {
    if (file) {
      this.fileListItemUploads = [file] as FileUploadDetails[];
    }
  }

  /**
   * @description Triggered after file was deleted, calls functions with null/empty parameters
   * @returns { void }
   */
  deleteFile(): void {
    this.updateForFileChange(null, []);
    let control = this.form.get(this.fileUpload.id);
    control?.patchValue(null);
  }

  /**
   * @description Triggered after file was added, gets file from event and called function with corrects params
   * @param { FileList | null } files The file being uploaded as a file list
   * @returns { void }
   */
  fileUploadChange(files: FileList | null): void {
    if (files?.length) {
      let control = this.form.get(this.fileUpload.id);
      control?.markAsDirty();

      const uploadedFile: File = files[0];
      const fileUploadDetails = new FileUploadDetails();
      fileUploadDetails.displayName = uploadedFile.name;
      control?.patchValue(fileUploadDetails);

      let isValidFileExtension = FileService.isValidFileExtension(
        uploadedFile,
        this.fileUpload.fileExtensionPermissionType,
        this.fileUpload.extensions,
      );
      let isValidFileSize = FileService.isValidFileSize(
        uploadedFile,
        this.fileUpload.fileSizeLimit,
      );

      if (isValidFileExtension && isValidFileSize) {
        this.updateForFileChange(fileUploadDetails, [uploadedFile]);
        this.fileUpload.storageOptions = new FileUploadAzureStorageOptions();
      } else {
        if (!isValidFileExtension) {
          control?.setErrors({
            invalidFile: 'invalid file type',
            ...control?.errors,
          });
        }
        if (!isValidFileSize) {
          control?.setErrors({
            invalidFile:
              'Maximum file size is ' + this.fileUpload.fileSizeLimit + 'mb',
            ...control?.errors,
          });
        }
      }
    }
  }

  /**
   * @description Makes changes to values after file change and emits value
   * @param { FileUploadDetails | null } fileUploadDetails Details of uploaded file
   * @param { File[] | undefined } uploadedFile Upload file object array
   * @returns { void }
   */
  updateForFileChange(
    fileUploadDetails: FileUploadDetails | null,
    uploadedFile: File[] | undefined,
  ): void {
    this.fileArray = uploadedFile ?? [];
    this.fileListItemUploads = fileUploadDetails ? [fileUploadDetails] : [];
    this.change(null, uploadedFile);
  }

  fileExtensionValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (
        control.value?.displayName &&
        this.fileUpload.extensions &&
        this.fileUpload.extensions.length > 0 &&
        !FileService.allowedOrDisallowedFileExt(
          control.value.displayName,
          this.fileUpload.fileExtensionPermissionType,
          this.fileUpload.extensions,
        )
      ) {
        return { invalidFileExtension: control.value.displayName };
      }
      return null;
    };
  }
}
