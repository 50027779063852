import { Question } from './Question';

export class FileUpload extends Question<FileUploadDetails | undefined> {
  storageOptions?: FileUploadStorageOptions;
  fileSizeLimit?: number;
  extensions?: string[];
  fileExtensionPermissionType?: FileExtensionPermissionType;
  showImagePreview?: boolean;
  annotateImage?: boolean;
  annotationColor?: string;
  hideUploadButton?: boolean;
  hideUploadLink?: boolean;
  fileUploadDetails?: FileUploadDetails;
}

export class FileUploadDetails {
  displayName?: string;
  fileUrl?: string;
}

export abstract class FileUploadStorageOptions {
  abstract type: FileUploadStorageType;
  overwrite?: boolean;
}

export class FileUploadSharepointStorageOptions extends FileUploadStorageOptions {
  type: FileUploadStorageType = FileUploadStorageType.Sharepoint;
  listPath?: string;
  documentLibrary?: string;
}

export class FileUploadAzureStorageOptions extends FileUploadStorageOptions {
  type: FileUploadStorageType = FileUploadStorageType.Azure;
}

export enum FileUploadStorageType {
  Sharepoint = 'Sharepoint',
  Azure = 'AzureBlobStorage',
}

export enum FileExtensionPermissionType {
  Allowed = 'Allow',
  Disallowed = 'Disallow',
}
