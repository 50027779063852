import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ff-upload-list-file-item',
  templateUrl: './ff-upload-list-file-item.component.html',
  styleUrls: ['./ff-upload-list-file-item.component.scss'],
})
export class FFUploadListFileItemComponent {
  @Input() fileName?: string;
  @Input() readOnly = false;
  @Input() isFileValid: boolean = true;

  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();

  handleClick() {
    this.onClick.emit();
  }

  handleDeleteClick() {
    this.onDelete.emit();
  }
}
