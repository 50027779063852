<div class="modal-header">
  <div>
    <i class="bi bi-reply-all-fill"></i>
    <h5 class="d-inline ms-1">Re-open Step</h5>
  </div>
  <i class="bi bi-x fs-1 close-icon" (click)="closeModal()"></i>
</div>

<div class="modal-body">
  <ff-message-divider
    [alertType]="alertTypeEnum.Warning"
    [title]="'Please note'"
    [message]="
      'Re-opening a step opens all subsequent steps to ensure changes are applied across the flow.'
    "
  ></ff-message-divider>

  <div class="mb-4">
    <h4>{{ stepTitle }}</h4>
    <ff-text
      class="fw-light"
      [text]="'Re-opening step ' + stepNumber"
    ></ff-text>
    <ff-separator></ff-separator>
    <i *ngIf="!avatarImage" class="bi bi-person-circle h5 text-primary"></i>
    <img
      *ngIf="avatarImage"
      class="img-fluid rounded-circle w-10"
      [src]="avatarImage"
      alt="image of a person"
    />
    <span class="py-1 px-2 ms-2 rounded fs-8 fw-bold background-color">{{
      stepAssignedTo
    }}</span>
  </div>

  <div
    class="d-flex flex-column gap-2 mb-5 date-select-width"
    [formGroup]="form"
  >
    <ff-text class="fw-semibold" [text]="'Due date'"></ff-text>

    <ff-text
      class="fs-7 text-body-secondary"
      [text]="'Needed by close of business:'"
    ></ff-text>

    <ff-radio
      formControlName="radioSelect"
      [id]="'StepReopeningConfirmationDialogRadioSelection'"
      [enabled]="true"
      [options]="radioOptionsString"
      (OnChange)="radioBtnChange($event)"
    >
    </ff-radio>
    <ff-date-only
      *ngIf="showDatePicker"
      formControlName="dateSelect"
      [id]="'StepReopeningConfirmationDialogDateSelection'"
      [placeHolder]="'Select date'"
      (OnChange)="dateChange($event)"
    ></ff-date-only>
  </div>

  <div class="modal-footer">
    <button class="btn btn-outline-primary" (click)="closeModal()">
      Cancel
    </button>
    <button class="btn btn-primary" (click)="submitForm()">Continue</button>
  </div>
</div>
