<div>
  <div
    [ngClass]="{
      title: true
    }"
  >
    <div class="mb-2">
      <ff-label
        [label]="label"
        [tooltip]="tooltip"
        [required]="required"
        [showTitle]="showTitle"
        [controlId]="id"
      />
      <ng-content />
    </div>
  </div>
  <div class="col-sm-10">
    <input
      type="text"
      readonly
      class="form-control-plaintext"
      [value]="value"
      [id]="id"
    />
  </div>
</div>
