<div [formGroup]="form" class="mb-5">
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>
  <ff-control-validation
    *ngIf="hasErrors"
    [errors]="control.errors"
    [label]="label"
  />

  <div class="mb-3">
    <ff-input-number
      [class.is-invalid]="hasErrors"
      [title]="label"
      formControlName="numberBox"
      [id]="id"
      [minimum]="minimum"
      [maximum]="maximum"
      [step]="step"
      [decimalPlaces]="decimalPlaces"
      (change)="change($event)"
    ></ff-input-number>
  </div>

  <div class="position-relative">
    <input
      type="range"
      class="form-range"
      formControlName="slider"
      [class.is-invalid]="hasErrors"
      [id]="id"
      [min]="minimum"
      [max]="maximum"
      [step]="step"
      (change)="change($event)"
    />

    <div class="position-absolute top-0 start-0 pe-none">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [style.visibility]="
          form.controls['slider'].value != minimum ? 'visible' : 'hidden'
        "
      >
        <rect
          x="1.00001"
          y="1.00101"
          width="10"
          height="10"
          rx="5"
          fill="white"
          stroke="#4D9EFF"
          stroke-width="2"
        />
      </svg>
      <p class="top-50">{{ minimum }}</p>
    </div>
    <div class="position-absolute top-0 start-50 pe-none">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [style.visibility]="
          form.controls['slider'].value != median ? 'visible' : 'hidden'
        "
      >
        <rect
          x="1.00001"
          y="1.00101"
          width="10"
          height="10"
          rx="5"
          fill="white"
          stroke="#4D9EFF"
          stroke-width="2"
        />
      </svg>
      <p class="top-50">{{ median }}</p>
    </div>
    <div class="position-absolute top-0 end-0 pe-none">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [style.visibility]="
          form.controls['slider'].value != maximum ? 'visible' : 'hidden'
        "
      >
        <rect
          x="1.00001"
          y="1.00101"
          width="10"
          height="10"
          rx="5"
          fill="white"
          stroke="#4D9EFF"
          stroke-width="2"
        />
      </svg>
      <p class="top-50">{{ maximum }}</p>
    </div>
  </div>
</div>
