import { Component, ViewEncapsulation } from '@angular/core';
import { ButtonType } from '../visual-components/components/ff-button/ff-button.component';
import { AlertType } from '../visual-components/components/ff-message-divider/ff-message-divider.component';
import { ListImageItem } from '../visual-components/components/ff-upload-list-images/ff-upload-list-images.component';
import { FileUploadDetails } from '../models/FileUpload';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs';

@Component({
  selector: 'app-vc-showcase',
  templateUrl: './vc-showcase.component.html',
  styleUrls: ['./vc-showcase.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VcShowcaseComponent {
  constructor(private httpClient: HttpClient) {}

  callRuleApi() {
    this.httpClient.get('https://localhost:7178/Rule').subscribe((res) => {
      console.log(res);
    });
  }
  primary: ButtonType = ButtonType.Primary;
  secondary: ButtonType = ButtonType.Secondary;
  danger: ButtonType = ButtonType.Danger;
  alertTypeEnum: typeof AlertType = AlertType;

  inputNumberInitial: number = 10;

  listItemsWithPreviews: ListImageItem[] = [
    {
      fileName: 'image1.jpg',
      image:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Unicode_0x0041.svg/800px-Unicode_0x0041.svg.png',
    },
    {
      fileName: 'image2.jpg',
      image:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Unicode_0x0042.svg/800px-Unicode_0x0042.svg.png',
    },
    {
      fileName: 'image3.jpg',
      image:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/IPA_Unicode_0x0063.svg/1200px-IPA_Unicode_0x0063.svg.png',
    },
  ];
  listItemsWithoutPreviews: FileUploadDetails[] = [
    { displayName: 'file1.png' },
    { displayName: 'file2.png' },
    { displayName: 'file3.png' },
  ];
}
