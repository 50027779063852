import { Component, Input } from '@angular/core';

@Component({
  selector: 'ff-title-description',
  templateUrl: './ff-title-description.component.html',
})
export class FFTitleDescriptionComponent {
  @Input() title?: string;
  @Input() description?: string;
}
