import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'ff-validation-navigation',
  templateUrl: './ff-validation-navigation.component.html',
  styleUrls: ['./ff-validation-navigation.component.scss'],
})
export class FFValidationNavigationComponent implements OnInit {
  @Input() form!: FormGroup;

  private _invalidControls: FormControl[] = [];

  ngOnInit() {
    this.form.statusChanges.subscribe(() => {
      this._invalidControls = this.getInvalidControls2();
    });
  }

  navigateToPreviousInvalid() {
    const index = this._invalidControls.findIndex((control) => {
      return control.invalid && control.touched;
    });

    if (index > 0) {
      this.setFocusOnControl(this._invalidControls[index - 1]);
    }
  }

  navigateToNextInvalid() {
    const index = this._invalidControls.findIndex((control) => {
      return control.invalid && control.touched;
    });

    if (index < this._invalidControls.length - 1) {
      this.setFocusOnControl(this._invalidControls[index + 1]);
    }
  }

  hasPreviousInvalid() {
    return (
      this._invalidControls.findIndex((control) => {
        return control.invalid && control.touched;
      }) > 0
    );
  }

  hasNextInvalid() {
    return (
      this._invalidControls.findIndex((control) => {
        return control.invalid && control.touched;
      }) <
      this._invalidControls.length - 1
    );
  }

  private getInvalidControls2(): FormControl[] {
    const invalidControls: FormControl[] = [];

    const collectInvalidControls = (control: FormType) => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        const nestedControls = Object.values(control.controls) as FormControl[];
        for (const nestedControl of nestedControls) {
          collectInvalidControls(nestedControl);
        }
      } else {
        if (control.invalid) {
          invalidControls.push(control);
        }
      }
    };

    collectInvalidControls(this.form);

    return invalidControls;
  }

  private getInvalidControls() {
    const invalidControls: FormControl[] = [];

    const collectInvalidControls = (control: FormType) => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        const nestedControls = Object.values(control.controls) as FormControl[];
        for (const nestedControl of nestedControls) {
          collectInvalidControls(nestedControl);
        }
      } else {
        if (control.invalid) {
          invalidControls.push(control);
        }
      }
    };

    collectInvalidControls(this.form);

    return invalidControls;
  }

  private setFocusOnControl(control: any) {
    const invalidControl = document.getElementById(control.id);
    if (invalidControl) {
      invalidControl.focus();
    }
  }
}

export type FormType = FormArray | FormGroup | FormControl;
