import { Pipe, PipeTransform } from '@angular/core';

import { BaseQuestion } from '../../../../models/Question';
import { QuestionType } from 'src/app/models/QuestionType';

@Pipe({
  name: 'filterStepper',
})
export class FilterStepperPipe implements PipeTransform {
  questionType: typeof QuestionType = QuestionType;

  transform(
    questions?: BaseQuestion[],
    invalidQuestionIds?: string[],
  ): BaseQuestion[] | [] {
    if (questions)
      return questions?.filter(
        (q) =>
          q.questionType === this.questionType.Section ||
          (invalidQuestionIds?.includes(q.id) ?? false) ||
          q.commentThread?.isAddressed === false,
      );
    else return [];
  }
}
