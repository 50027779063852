<div
  [ngClass]="{
    'bg-light rounded-bottom': isFooter,
    'rounded-top': !index && !isFooter
  }"
  class="card p-0 rounded-0"
>
  <div class="card-body pt-0">
    <div ngbAccordion>
      <div
        ngbAccordionItem
        [ngClass]="{ 'bg-light': isFooter }"
        [collapsed]="false"
        [disabled]="false"
      >
        <h2 ngbAccordionHeader>
          <button
            ngbAccordionButton
            [ngClass]="{ 'bg-light': isFooter }"
            class="mb-n3 px-0 shadow-none"
          >
            <div class="d-flex">
              <ng-container *ngIf="!isFooter">
                <ng-container *ngIf="isRowNumbersHidden">
                  <span class="text-black fw-semibold">#{{ index + 1 }}</span>
                </ng-container>
                <h6
                  class="mb-0 text-black fw-semibold"
                  [ngClass]="{ 'ps-2': isRowNumbersHidden }"
                >
                  {{ tableRow[0].questionInfo.title }}
                </h6>
              </ng-container>

              <h6 class="text-black fw-semibold" *ngIf="isFooter">Total</h6>
            </div>
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="px-4 py-0">
            <ng-template>
              <ng-container *ngIf="!isFooter">
                <ng-container *ngFor="let row of tableRow; let i = index">
                  <div class="pb-2" [ngClass]="{ 'mt-3': !i }">
                    <question-selector
                      [question]="row.questionInfo"
                      [form]="form"
                      (questionEvent)="onQuestionEvent($event)"
                      [readOnly]="isReadOnly"
                      [showCommentIcon]="false"
                    />
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="isFooter">
                <div
                  *ngFor="let footer of tableFooter; let i = index"
                  class="pb-2"
                  [ngClass]="{ 'mt-3': !i }"
                >
                  <question-selector
                    [question]="footer"
                    [form]="form"
                    (questionEvent)="onQuestionEvent($event)"
                    [showCommentIcon]="false"
                  />
                </div>
              </ng-container>

              <ng-container *ngIf="!isReadOnly">
                <div
                  *ngIf="!isFooter && !isRowsFixed"
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    *ngIf="enableRowDuplication"
                    class="btn"
                    (click)="rowDuplicate(tableRow[0].cellAnswer.rowNumber)"
                  >
                    <i class="bi bi-files text-primary"></i>
                  </button>
                  <button
                    *ngIf="!isRemoveButtonHidden && mappedRows.length > 1"
                    class="btn text-danger"
                    (click)="rowRemove(tableRow[0].cellAnswer.rowNumber)"
                  >
                    <i class="bi bi-trash3"></i>
                  </button>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
