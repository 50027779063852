<div [formGroup]="form">
  <ff-button
    *ngIf="button && button.visible"
    [tooltip]="button.description"
    [label]="question.title"
    [id]="button.id"
    [required]="question.required"
    [showTitle]="question.showTitle"
    [buttonType]="buttonType"
    [enabled]="question.enabled && !readOnly"
    [textValue]="button.textValue"
    [showCommentIcon]="showCommentIcon"
    (OnClick)="change()"
  >
    <ng-content commentIcon />
  </ff-button>
</div>
