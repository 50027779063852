<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>
  <ff-control-validation
    *ngIf="hasErrors"
    [errors]="control.errors"
    [label]="label"
  />

  <div
    class="d-flex align-items-center"
    [ngClass]="{
      'flex-column gap-2': !horizontalLayout,
      'gap-4': horizontalLayout
    }"
    [id]="id"
  >
    <div
      class="d-flex gap-2 align-self-stretch"
      [ngClass]="{ 'd-inline-flex': !horizontalLayout }"
      *ngFor="let option of options; let index = index"
    >
      <p-radioButton
        [value]="option.value || option"
        [formControl]="formControl"
        [inputId]="id! + index"
        [class.ng-invalid]="hasErrors"
        [class.ng-dirty]="hasErrors"
        (onClick)="onChangeEvent()"
      ></p-radioButton>
      <label
        class="form-check-label"
        [for]="id! + index"
        [class.text-danger]="hasErrors"
      >
        {{ option.displayName || option }}
      </label>
    </div>
  </div>
</div>
