<div>
  <div class="mb-2">
    <ff-label
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
      [showTitle]="showTitle"
      [controlId]="id"
    />
    <ng-content />
  </div>
  <ff-control-validation
    *ngIf="hasErrors"
    [errors]="control.errors"
    [label]="errorMessageSuffix || label"
  />
  <input
    type="{{ this.formControl.value ? 'date' : 'text' }}"
    onfocus="(this.type='date')"
    (blur)="onBlur($event, 'date')"
    (touchstart)="onTouchStart($event)"
    (touchmove)="onTouchMove($event)"
    (touchend)="onTouchEnd($event, 'date')"
    [placeholder]="placeHolder"
    class="form-control"
    max="9999-12-31"
    [formControl]="formControl"
    [id]="id"
    [class.is-invalid]="hasErrors"
  />
</div>
