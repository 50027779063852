import { Component, Input } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { FFxUser } from 'src/app/models/PersonOrGroup';
import { PersonOrGroupService } from 'src/app/common/services/person-or-group/person-or-group.service';
import { NgControl } from '@angular/forms';
import { FFInputComponentBase } from '../ff-base/ff-input-component-base';

@Component({
  selector: 'ff-people-picker',
  templateUrl: './ff-people-picker.component.html',
  styleUrls: ['./ff-people-picker.component.scss'],
  providers: [{ provide: NgControl, useValue: NgControl }],
})
export class FFPeoplePickerComponent extends FFInputComponentBase {
  constructor(
    private _personOrGroupService: PersonOrGroupService,
    protected ngControl: NgControl,
  ) {
    super(ngControl);
  }

  @Input() customSearchFunction?: (term: string) => Observable<FFxUser[]>;

  @Input()
  value?: FFxUser[];

  @Input()
  allowMultiple: boolean = false;

  options!: FFxUser[];

  override ngOnInit() {
    super.ngOnInit();
    this.formControl.patchValue(this.value);
  }

  async search(event: AutoCompleteCompleteEvent): Promise<void> {
    let response: Observable<FFxUser[]>;
    if (this.customSearchFunction) {
      response = this.customSearchFunction(event.query);
    } else {
      response = this._personOrGroupService.GetPeopleAndGroups(event.query);
    }
    const responseValues = await firstValueFrom(response);
    this.options = responseValues ?? [];
  }

  get isReadOnly(): boolean {
    return this.formControl.value?.length > 0 && !this.allowMultiple;
  }

  onSelect(option: FFxUser): void {
    this.onChangeEvent(this.formControl.value);
  }

  onUnselect(value: FFxUser): void {
    this.onChangeEvent(this.formControl.value);
  }

  getFFxUserEmail(loginName?: string): string {
    if (loginName) {
      const splitLoginName = loginName.split('|');
      return splitLoginName.length == 3 ? splitLoginName[2] : '';
    }
    return '';
  }
}

export interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
