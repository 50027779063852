export enum QuestionType {
  SingleLineOfText = 'SingleLineOfText',
  Separator = 'Separator',
  Choice = 'Choice',
  YesNo = 'YesNo',
  Button = 'Button',
  MultipleLinesOfText = 'MultipleLinesOfText',
  Number = 'Number',
  DateAndTime = 'DateAndTime',
  FileUpload = 'FileUpload',
  PersonOrGroup = 'PersonOrGroup',
  Section = 'Section',
  Table = 'Table',
  Calculated = 'Calculated',
}
