<div class="card">
  <table
    class="d-flex flex-column align-items-start align-self-stretch p-2 px-4 py-2 overflow-x-scroll"
  >
    <caption>
      Table Question
    </caption>
    <thead class="d-flex flex-column p-2 flex-grow-1">
      <tr class="d-flex align-items-center">
        <th
          *ngIf="this.tableQuestion.showRowNumbers"
          class="flex-column row-number"
        ></th>
      </tr>
    </thead>

    <tbody class="d-flex flex-column p-2 flex-grow-1">
      <tr
        *ngFor="let row of mappedRows; let i = index"
        class="d-flex align-items-end justify-content-center"
      >
        <td
          *ngIf="this.tableQuestion.showRowNumbers"
          class="flex-column row-number"
        >
          #{{ i + 1 }}
        </td>

        <td
          *ngFor="let cell of row"
          class="d-flex flex-column py-1 px-2 flex-grow-1 table-row-item-width"
        >
          <question-selector
            [question]="cell.questionInfo"
            [form]="form"
            (questionEvent)="onQuestionEvent($event)"
            [readOnly]="isReadOnly"
            [showCommentIcon]="false"
          />
        </td>

        <ng-container *ngIf="!isRowsFixed && !isReadOnly">
          <button
            *ngIf="enableRowDuplication"
            class="btn"
            (click)="rowDuplicate(row[0].cellAnswer.rowNumber)"
          >
            <i class="bi bi-files text-primary"></i>
          </button>

          <button
            *ngIf="!isRemoveButtonHidden && mappedRows.length > 1"
            class="btn text-danger"
            (click)="rowRemove(row[0].cellAnswer.rowNumber)"
          >
            <i class="bi bi-trash3"></i>
          </button>
        </ng-container>
      </tr>

      <tr
        *ngIf="mappedFooterQuestions.length"
        class="d-flex align-items-center justify-content-center"
      >
        <td
          *ngIf="this.tableQuestion.showRowNumbers"
          class="flex-column row-number"
        ></td>

        <td
          *ngFor="let questions of mappedFooterQuestions"
          class="d-flex flex-column py-1 px-2 flex-grow-1 table-row-item-width"
        >
          <div *ngIf="questions; else empty">
            <ng-container *ngFor="let question of questions">
              <question-selector
                [question]="question"
                [form]="form"
                (questionEvent)="change($event)"
                [readOnly]="isReadOnly"
              />
            </ng-container>
          </div>
          <ng-template #empty></ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
